import React from 'react'
import { connect } from 'react-redux'
import { productActions } from 'modules/Product/product.redux'
import { Link } from 'react-router-dom'

// mapear prodiedas
// agregar actions de shoppingcart
// agregar actions de whishlist
function ListProductItem({ product }) {
	return product ? (
		<Link to={`/products/${product.id}`} className="latest-product-item">		
			<div>
				<h6>{product.name || product.product.name}</h6>				
			</div>
		</Link>
	) : null
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {
	loadAllPublicProduct: productActions.loadAllPublicProductRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(ListProductItem)
