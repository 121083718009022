import React from 'react'
import SearchBar from 'components/SearchBar/SearchBar'
import BannerShopProducts from '../../components/Banners/BannerShopProducts'
import WishList from '../../components/WishList/WishList'
import Public from 'components/Layout/Layout'
import CategoriesMenu from 'components/Categories/CategoriesMenu'

function WishListPage() {
	return (
		<Public fluid admin={false}>
			<SearchBar>
				<CategoriesMenu />
			</SearchBar>
			<BannerShopProducts text="Lista de Deseos" />
			<WishList />
		</Public>
	)
}

export default WishListPage
