import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import WishListItem from './WishListItem'

function WishList({ wishList }) {
	const wish = wishList.length

	return wish > 0 ? (
		<section className="shoping-cart spad">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="wish-list-table">
							<table>
								<thead>
									<tr>
										<th style={{ paddingLeft: '3%' }}>Producto</th>
										<th>Descripción</th>
										<th>Disponibilidad</th>
										<th>Precio</th>
										<th style={{ paddingLeft: '3%' }}>Eliminar</th>
									</tr>
								</thead>
								<tbody>{wishList.map((p) => <WishListItem key={p.whishListId} {...p} />)}</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="wish-list-btn">
							<Link to="/Shop" className="primary-btn cart-btn">
								VER PRODUCTOS
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	) : (
		<div className="alert-empty">
			<div className="alert alert-info" role="alert">
				<h4 className="alert-heading">Tu wish list esta vacia</h4>
				<p className="mb-0">
					Te invitamos a ver nuestros{' '}
					<a style={{ color: '#0c5460', fontWeight: 'bold', textDecoration: 'underline' }} href="/shop">
						{' '}
						productos{' '}
					</a>
				</p>
			</div>
		</div>
	)
}

const mapStateToProps = ({ wishList }) => ({ wishList })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(WishList)
