import React from 'react'
import { connect } from 'react-redux'
import { removeProductFromWishList, getWishList } from 'redux/global/wishList/wishList.redux'

export const WishListItem = ({ whishListId, product, removeProductFromWishList }) => {
	const { name, stock, price, category } = product
	return (
		<tr>
			<td className="wish-list-item">
				<div className="product-img" alt="">
					<img src={product.thumbnail.path} alt={product.name} />
				</div>
			</td>
			<td>
				<h5>{name}</h5>
				<p>Categoría: {category ? category.title : ''}</p>
			</td>
			<td className="wish-list-stock">
				<h5>{stock} Piezas </h5>
			</td>
			<td className="wish-list-price">{price}</td>

			<td className="wish-list-item-close">
				<button onClick={() => removeProductFromWishList(whishListId)} className="icon-remove" />
			</td>
		</tr>
	)
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {
	removeProductFromWishList: removeProductFromWishList,
	getWishList: getWishList
}
export default connect(mapStateToProps, mapDispatchToProps)(WishListItem)
