import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take, select } from 'redux-saga/effects'
import { alertActions, alertTypes } from '././../Alert/alert.redux'
import { productActions, productTypes } from './product.redux'
import { isEmpty } from './../../util/crud'
import { GET_VISITS_FROM_PRODUCT_REQUEST } from 'modules/Visits/visits.redux'
import { reviewsActions } from 'modules/Reviews/reviews.redux'

/**
 * Display the products list
 * @returns {iterator}
 */
export function* loadAllProduct() {
  try {
    const url = '/api/products/'
    const { data: products } = yield call(axios.get, url)
    if (isEmpty(products)) {
      yield put(alertActions.alertMessageEmpty('product', '/dashboard/product/create'))
    }
    yield put(productActions.loadAllProductSuccess(products))
  } catch (error) {
    yield put(productActions.loadAllProductFailure(error))
  }
}

/**
 * Display the products public
 * @returns {iterator}
 */
export function* loadAllPublicProduct() {
  try {
    const url = '/api/public/products/'
    const { data: products } = yield call(axios.get, url)

    yield put(productActions.loadAllPublicProductSuccess(products))
  } catch (error) {
    yield put(productActions.loadAllPublicProductFailure(error))
  }
}

/**
 * Display the products home public
 * @returns {iterator}
 */
export function* loadHomeProduct() {
  try {
    const url = '/api/public/products/getHomeProducts'
    const { data: products } = yield call(axios.post, url)

    yield put(productActions.loadAllHomeProductSuccess(products))
  } catch (error) {
    yield put(productActions.loadAllHomeProductFailure(error))
  }
}

/**
 * Display the products home public
 * @returns {iterator}
 */
export function* loadAllHomeProduct() {
  try {
    const url = '/api/public/products/readManyLimit'
    const { data: products } = yield call(axios.post, url)

    yield put(productActions.loadProductHomeSuccess(products))
  } catch (error) {
    yield put(productActions.loadProductHomeFailure(error))
  }
}

/**
 * Display a product
 * @returns {iterator}
 */
export function* publicLoadProduct(action) {
  try {
    const productId = action.productId
    const url = `/api/public/products/${productId}`
    const { data: product } = yield call(axios.get, url)
    yield put(productActions.loadPublicProductSuccess(product))
  } catch (error) {
    yield put(productActions.loadPublicProductFailure(error))
  }
}

/**
 * Display a single product record
 * @param   {object}   action.payload Data to take the id of the requested product
 * @returns {iterator}
 */
export function* loadProduct({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/products/${id}`
    const { data: product } = yield call(axios.get, url)
    yield put(productActions.loadProductSuccess(product))
  } catch (error) {
    yield put(productActions.loadProductFailure(error))
  }
}

/**
 * Create an product record
 * @param   {object}   action.payload Data to create an product record
 * @returns {iterator}
 */
export function* createProduct({ payload }) {
  try {
    const url = '/api/products/'
    const load = {
      ...payload,
      category: payload.category.value
    }
    // Make the POST request
    const { data } = yield call(axios.post, url, load)
    // Add new document to the list
    yield put(productActions.createProductSuccess(data))
    // Show notification
    yield put(alertActions.alertMessageSuccess('Registro guardado'))
    // Return the user to the list
    yield put(push('/dashboard/product/list'))
  } catch (error) {
    yield put(productActions.createProductFailure(error))
  }
}

/**
 * Load the information of a single product record to edit it
 * @param   {object}   action.payload Data to take the id of the requested product
 * @returns {iterator}
 */
export function* editProduct({ payload } = {}) {
  try {
    const formatOptions = key => option => ({ value: option._id, label: option[key] })
    yield call(loadProductOptions)

    const { id } = payload
    const url = `/api/products/${id}`
    const { data: product } = yield call(axios.get, url)
    const response = {
      ...product,
      category: formatOptions('title')(product.category),
      thumbnail: product.thumbnail._id
    }
    yield put(productActions.editProductSuccess(response))
  } catch (error) {
    yield put(productActions.editProductFailure(error))
  }
}

function formatProduct(payload) {
  return {
    ...payload,
    category: payload.category.value
  }
}

/**
 * Update an product record
 * @param   {object}   action.payload Data to update an product record
 * @returns {iterator}
 */
export function* updateProduct({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/products/${id}`

    const historical = { cause: 'Actualización', description: prompt.payload }
    const load = formatProduct(values)
    const data = { payload: load, historical }
    const { data: updatedProduct } = yield call(axios.put, url, data)
    yield put(productActions.updateProductSuccess(updatedProduct))
    // Success notification and return the user to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    yield put(push('/dashboard/product/list'))
  } catch (error) {
    yield put(productActions.updateProductFailure(error))
  }
}

/**
 * Toggle the active property of an product record
 * @param   {object}   action.payload Data to update an product record
 * @returns {iterator}
 */
export function* toggleProduct({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)
    const description = prompt.payload

    // The prompt was closed, stop the flow
    if (isEmpty(description)) return

    const { _id, active } = payload
    const url = `/api/products/${_id}`

    // Make the PUT request
    const historical = { cause: active ? 'Desactivación' : 'Activación', description }
    const { data: updatedProduct } = yield call(axios.put, url, {
      payload: { active: !active },
      historical
    })
    yield put(productActions.toggleProductSuccess(updatedProduct))

    // Show success notification
    const result = updatedProduct.active ? 'activado' : 'desactivado'
    yield put(alertActions.alertMessageSuccess(`Registro ${result}`))
    yield put(push('/dashboard/product/list'))
  } catch (error) {
    yield put(productActions.toggleProductFailure(error))
  }
}

export function* loadProductOptions() {
  try {
    const { data: categories } = yield call(axios.get, '/api/options/categories')
    const payload = { categories }
    yield put(productActions.loadProductOptionsSuccess(payload))
  } catch (error) {
    yield put(productActions.loadProductOptionsFailure(error))
  }
}
/**
 * Validate an product record
 * @param     values
 * @returns
 */
export async function validateProduct(values) {
  const url = '/api/products/validate'
  const { data: result } = await axios.post(url, values)
  if (!isEmpty(result.errors)) throw result.errors
}

//Obtenemos los productos desde la base de datos
//y se muestran en la vista
export function* getLatestProductsSaga(action) {
  try {
    const { data: latestProducts } = yield call(
      axios.post,
      '/api/public/products/getLatestProducts'
    )
    yield put(productActions.loadLatestProductsSuccess(latestProducts))
  } catch (error) {
    yield put(productActions.loadLatestProductsFailure(error))
  }
}

//Obtenemos los ultimos productos vistos desde la base de datos
export function* getVisitsProductsSaga(action) {
  try {
    const { data: visitsProducts } = yield call(axios.get, '/api/visits/getVisitsProducts')
    yield put(productActions.loadVisitsProductsSuccess(visitsProducts))
  } catch (error) {
    yield put(productActions.loadVisitsProductsFailure(error))
  }
}

//Obtenemos los ultimos productos con mas comentarios de la base de datos
// Aqui realice el saga de review products
export function* getReviewsProductsSaga(action) {
  try {
    const { data: reviewProducts } = yield call(
      axios.post,
      '/api/public/products/getReviewProductsComments'
    )
    yield put(productActions.loadReviewsProductsSuccess(reviewProducts))
  } catch (error) {
    yield put(productActions.loadReviewsProductsFailure(error))
  }
}

export function* loadViewProduct(action) {
  try {
    const productId = action.productId
    yield put(productActions.loadPublicProductRequest(productId))
    yield put(reviewsActions.loadAllProductReviewsRequest(productId))
    yield put({ type: GET_VISITS_FROM_PRODUCT_REQUEST, product: productId })
    yield put(productActions.loadViewProductSuccess(productId))
  } catch (error) {
    yield put(productActions.loadViewProductFailure(error))
  }
}

/**
 * Display a products similar at name
 * @param   {object}   action.payload Data to take the name of the requested product
 * @returns {iterator}
 */
export function* searchProduct({ payload } = {}) {
  try {
    const { name } = payload
    const url = `/api/public/products/search/${name}`
    const { data: products } = yield call(axios.get, url)
    // Redirect the searchView
    yield put(push('/searchProduct'))
    yield put(productActions.searchProductSuccess(products))
  } catch (error) {
    yield put(productActions.searchProductFailure(error))
  }
}

/**
 * Display a products similar at name
 * @param   {object}   action.payload Data to take the name of the requested product
 * @returns {iterator}
 */
export function* searchProductsByCategory({ payload } = {}) {
  try {
    const { title } = payload
    const url = `/api/public/products/search/menu/${title}`
    const { data: products } = yield call(axios.post, url)
    // Redirect the searchView
    yield put(push('/shop'))
    yield put(productActions.searchProductByCategorySuccess(products))
  } catch (error) {
    yield put(productActions.searchProductByCategoryFailure(error))
  }
}

export function* productSagas() {
  yield all([
    takeLatest(productTypes.LOAD_ALL_PRODUCT_REQUEST, loadAllProduct),
    takeLatest(productTypes.LOAD_ALL_PUBLIC_PRODUCT_REQUEST, loadAllPublicProduct),
    takeLatest(productTypes.LOAD_PUBLIC_PRODUCT_REQUEST, publicLoadProduct),
    takeLatest(productTypes.LOAD_PRODUCT_REQUEST, loadProduct),
    takeLatest(productTypes.CREATE_PRODUCT_REQUEST, createProduct),
    takeLatest(productTypes.EDIT_PRODUCT_REQUEST, editProduct),
    takeLatest(productTypes.UPDATE_PRODUCT_REQUEST, updateProduct),
    takeLatest(productTypes.TOGGLE_PRODUCT_REQUEST, toggleProduct),
    takeLatest(productTypes.LOAD_PRODUCT_OPTIONS_REQUEST, loadProductOptions),
    takeLatest(productTypes.LOAD_LATEST_PRODUCTS_REQUEST, getLatestProductsSaga),
    takeLatest(productTypes.LOAD_VISITS_PRODUCTS_REQUEST, getVisitsProductsSaga),
    takeLatest(productTypes.LOAD_ALL_HOME_PRODUCT_REQUEST, loadHomeProduct),
    takeLatest(productTypes.LOAD_ALL_HOME_PRODUCT_REQUEST, loadAllHomeProduct),
    // Saga de review products
    takeLatest(productTypes.LOAD_REVIEWS_PRODUCTS_REQUEST, getReviewsProductsSaga),
    takeLatest(productTypes.LOAD_VIEW_PRODUCT_REQUEST, loadViewProduct),
    takeLatest(productTypes.SEARCH_PRODUCT_REQUEST, searchProduct),
    takeLatest(productTypes.SEARCH_PRODUCT_BY_CATEGORY_REQUEST, searchProductsByCategory)
  ])
}
