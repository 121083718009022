import { generateActiveIcon } from 'components/Table/helpers'
import { mdy } from './../../util/dates'

const roles = [ 'Administrador total', 'Administrador restringido', 'Usuario' ]

export const columns = [
	{ dataField: 'fullName', text: 'Nombre', sort: true },
	{ dataField: 'email', text: 'Correo', sort: true },
	{ dataField: 'role', text: 'Rol', formatter: (cell) => roles[cell], sort: true },
	{ dataField: 'updatedAt', text: 'Última modificación', sort: true, formatter: mdy },
	{ dataField: 'createdAt', text: 'Creado', sort: true, formatter: mdy },
	{
		dataField: 'active',
		text: 'Activo',
		formatter: (cell) => generateActiveIcon(cell),
		sort: true
	}
]
