import React from 'react'
import { connect } from 'react-redux'
import { addProductToWishList } from 'redux/global/wishList/wishList.redux'
import { addProductToShoppingCart } from 'redux/global/shoppingCart/shoppingCart.redux'

export const ProductDetails = ({
  product,
  visits,
  addProductToWishList,
  addProductToShoppingCart
}) => {
  return (
    <div className='section-product'>
      <div className='row'>
        <div className='col-lg-6 col-md-6'>
          <div className='product-details-pic'>
            <div className='product-details-pic-item'>
              <img
                className='product-details-pic-item--large'
                src={product.thumbnail ? product.thumbnail.path : ''}
                alt={product.name}
              />
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-md-6'>
          <div className='product-details-text'>
            <h3>{product.name} </h3>
            <div className='product-details-rating'>
              <span>{visits} Vistas </span>
            </div>
            <div className='product-details-price'> {product.price} Puntos </div>
            <p>
              Categoría: {product.category ? product.category.title : ''}
              <br />
            {product.stock > 0 ? (
               'Disponibilidad: ' + product.stock + ' Piezas' 
              ) : (
                <h6 className='product-out-details'>Sin disponibilidad</h6>
              )}
              <br />
              {product.description}
            </p>

            <button className='primary-btn' onClick={() => addProductToShoppingCart(product)}>
              AGREGAR AL CARRITO
            </button>
            <button className='heart-icon' onClick={() => addProductToWishList(product)}>
              <i className='icon-heart' />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ visits }) => ({ visits })
const mapDispatchToProps = {
  addProductToShoppingCart: addProductToShoppingCart,
  addProductToWishList: addProductToWishList
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
