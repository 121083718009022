import OrderList from './OrderList'
import OrderEdit from './OrderEdit'
import OrderView from './OrderView'
// import OrderByUser from './OrderByUser'

const routes = [
	{ path: '/dashboard/order/list', component: OrderList },
	{ path: '/dashboard/order/edit/:id', component: OrderEdit },
	{ path: '/dashboard/order/view/:id', component: OrderView },
	// { path: '/dashboard/order/:id', component: OrderByUser }
]

export default routes
