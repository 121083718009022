import React from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import Loading from 'components/Loading/Loading'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import Navbar from 'components/Navbar/Navbar'
import Footer from 'components/Footer/Footer'

export function Public({ children, fluid, admin }) {
  if (admin) {
    return (
      <Layout admin>
        {/* <Separator /> */}
        <Container className='py-4' fluid={fluid}>
          {children}
        </Container>
      </Layout>
    )
  }

  return <Layout admin={false}>{children}</Layout>
}

function Layout({ children, admin }) {
  return (
    <Container fluid className='px-0'>
      <Loading />
      <Navbar admin={admin} />
      <ProgressBar />
      {children}
      <Footer />
    </Container>
  )
}

Public.defaultProps = {
  admin: true
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(Public)
