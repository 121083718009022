export const addItemToWishList = (wishList, product, whishListId) => {
	const check = wishList.find((i) => i.product.id === product.id)
	if (!check) {
		const newItem = {
			whishListId,
			product: {
				name: product.name,
				id: product.id,
				price: product.price,
				thumbnail: product.thumbnail,
				stock: product.stock,
				category: {
					title: product.category.title,
					id: product.category.id
				}
			}
		}
		return [ ...wishList, newItem ].sort((a, b) => a.id - b.id)
	}
}

export const removeItemFromWishList = (wishList, id) =>
	wishList.filter((l) => l.whishListId !== id).sort((a, b) => a.whishListId - b.whishListId)
