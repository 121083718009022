import React from 'react'
import { ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/style.css'

var listaEmails = new Array()

export class LoadEmails extends React.Component {
  state = {
    emails: listaEmails
  }

  render() {
    const { input } = this.props //input siempre es el puente para conectar redux form con un componente
    const { emails } = this.state
    return (
      <div>
        <ReactMultiEmail
          {...input}
          placeholder='Ingrese los correos electrónicos'
          name='emails'
          value={emails}
          emails={emails}
          onChange={emails => {
            input.onChange(emails) //
          }}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                {email}
                <span role='presentation' data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            )
          }}
        />
      </div>
    )
  }
}
