import { mdy } from './../../util/dates'

export const columns = [
	{ dataField: 'title', text: 'Título', sort: true },
	{ dataField: 'description', text: 'Descripción', sort: true },
	{ dataField: 'createdAt', text: 'Creado', sort: true, formatter: mdy },
	{ dataField: 'createdBy', text: 'Creado por', sort: true }
]
export const columnsPoints = [
	{ dataField: 'fullName', text: 'Nombre Completo', sort: true },
	{ dataField: 'description', text: 'Descripción', sort: true },
	{ dataField: 'points', text: 'Puntos', sort: true },
	{ dataField: 'email', text: 'Correo electrónico', sort: true }
]

export const getTotalPoints = (points) => {
	points.reduce((a, c) => a + c.total, 0)
}
