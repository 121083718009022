import React from 'react'
import { reduxForm } from 'redux-form'
import FormContainer from 'components/Container/FormContainer'
import { Form, Row, Col } from 'reactstrap'
import { withForm } from 'util/withForm'
import { RFCheckbox, RFInput, RFSelect, RFUploader } from 'components/Inputs/RFFields'
import { required, substring } from 'util/form'
import { connect } from 'react-redux'

const limit280 = substring(0, 280)
const limit20 = substring(0, 20)

function ProductForm({ options, ...props }) {
  const formatOptions = key => option => ({ value: option._id, label: option[key] })

  const { handleSubmit, onCancel, label, button, option, ...others } = props
  const { pristine, valid, submitting } = others

  return (
    <FormContainer
      title='Producto'
      label={label}
      button={button}
      onCancel={onCancel}
      onClick={handleSubmit}
      status={{ pristine, valid, submitting }}>
      <Form>
        <Row>
          <Col lg='6'>
            <RFInput
              name='name'
              type='text'
              label='Nombre'
              normalize={limit20}
              validate={[required]}
            />
          </Col>
          <Col lg='6'>
            <RFSelect
              name='category'
              label='Categoría'
              options={options.categories}
              validate={[required]}
              formatter={formatOptions('title')}
            />
          </Col>
        </Row>

        <Row>
          <Col lg='6'>
            <RFInput name='stock' type='number' label='Stock' validate={[required]} />
          </Col>
          <Col lg='6'>
            <RFInput name='price' type='number' label='Puntos' validate={[required]} />
          </Col>
        </Row>
        <RFInput
          name='description'
          type='textarea'
          label='Descripción'
          validate={[required]}
          normalize={limit280}
        />
        <RFUploader name='thumbnail' label='Imagen' validate={[required]} />
        <RFCheckbox
          className='checkbox-form'
          name='visibility.reviews'
          label='Mostrar comentarios'
        />
      </Form>
    </FormContainer>
  )
}

const mapStateToProps = ({ product }) => ({
  options: product.options
})
const withConnect = connect(mapStateToProps)

export default withConnect(
  withForm(
    reduxForm({
      form: 'product'
    })(ProductForm)
  )
)
