import React, { useEffect } from 'react'
import Layout from 'components/Layout/Layout'
import DataTable from 'components/Table/Table'
import { columns } from './load.helpers'
import TableContainer from 'components/Container/TableContainer'
import { connect } from 'react-redux'
import { loadActions } from './load.redux'
import { useExportSelected } from 'hooks/useExportSelected'

function LoadList(props) {
	const [ selectRow, handleExport ] = useExportSelected('Puntos', '/api/load/export')

	const rowEvents = {
		onClick: (e, row) => {
			const url = `/dashboard/load/view/${row._id}`
			props.history.push(url)
		}
	}
	useEffect(() => {
		props.loadAllLoad()
	}, [])

	return (
		<Layout>
			<TableContainer title="Puntos" subtitle="Lista" route="/dashboard/load/create" mbodule="Puntos">
				<DataTable
					{...props}
					rowEvents={rowEvents}
					data={props.loads}
					structure={columns}
					onExport={handleExport}
					selectRow={selectRow}
					selected={selectRow.selected}
					canDownload
				/>
			</TableContainer>
		</Layout>
	)
}

const mapStateToProps = ({ load }) => ({ loads: load.list })
const mapDispatchToProps = {
	loadAllLoad: loadActions.loadAllLoadRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadList)
