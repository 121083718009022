import React, { Component } from 'react'
import { connect } from 'react-redux'
import { categoryActions } from './category.redux'
import CategoryForm from './CategoryForm'
import Layout from 'components/Layout/Layout'

class CategoryCreate extends Component {
	submit = (values) => {
		this.props.createCategory(values)
	}

	render() {
		return (
			<Layout>
				<CategoryForm {...this.props} label="Agregar" button="add" onSubmit={this.submit} />
			</Layout>
		)
	}
}

const mapStateToProps = ({ category }) => ({ category })
const mapDispatchToProps = {
	createCategory: categoryActions.createCategoryRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCreate)
