import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { productActions } from 'modules/Product/product.redux'
import ListProductItem from './ListProductItem'

// Se muestra en Pagina Home con diferente grid
// Crear Query Para solo mostrar 3 productos Reated
export const ReviewProducts = ({ products, loadReviewProducts }) => {
	useEffect(() => {
		loadReviewProducts()
	}, [])

	return (
		<div className="col-lg-4 col-md-6" style={{padding:'0 0 0 18%'}}>
			<div className="latest-product-text">
				<h4>Últimas reseñas </h4>
				<div className="latest-product-slider">
					{products && products.map((p) => <ListProductItem key={p.id} product={p} />)}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({ product }) => ({ products: product.reviewProducts })
const mapDispatchToProps = {
	loadReviewProducts: productActions.loadReviewsProductsRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewProducts)
