import React from 'react'
import { Field } from 'redux-form'
import { Radio } from './RFRadio'
import { LoadEmails } from './RFLoadEmails'
import { Input } from './RFInput'
import { Select, SelectAsync } from './RFSelect'
import { Checkbox } from './RFCheckbox'
import { Uploader } from './RFUploader'
import { HTMLEditor } from './RFHTMLEditor'
import { compose } from 'redux'

export const InputWrapper =
  Input =>
  ({ meta, col = 12, ...props }) => {
    return (
      <div className={`col-md-${col}`}>
        <label htmlFor={props.id}>
          <strong>{props.label}</strong>
        </label>
        <Input
          valid={meta.touched && !meta.error}
          invalid={meta.touched && meta.error}
          {...props}
        />
        {meta.touched &&
          ((meta.error && <span className='text-danger'>{meta.error}</span>) ||
            (meta.warning && <span className='text-warning'>{meta.warning}</span>))}
        <br />
      </div>
    )
  }

export const CheckboxWrapper =
  Input =>
  ({ meta, ...props }) => {
    return (
      <div>
        <Input {...props} />
        <label htmlFor={props.id}>
          <strong>{props.label}</strong>
        </label>
        {meta.touched &&
          ((meta.error && <span className='text-danger'>{meta.error}</span>) ||
            (meta.warning && <span className='text-warning'>{meta.warning}</span>))}
        <br />
      </div>
    )
  }

// Wrapper to connect with Redux Form
export const UseWrapperRF = Input => props => {
  return <Field {...props} component={Input} />
}

// CONNECTED INPUT COMPONENTS
// TODO: Tags, TagsInput
const withRF = compose(UseWrapperRF, InputWrapper)

export const RFInput = withRF(Input)
export const RFRadio = withRF(Radio)
export const RFEmails = withRF(LoadEmails)
export const RFSelect = withRF(Select)
export const RFSelectAsync = withRF(SelectAsync)
export const RFCheckbox = UseWrapperRF(CheckboxWrapper(Checkbox))
export const RFUploader = withRF(Uploader)
export const RFHTMLEditor = withRF(HTMLEditor)
