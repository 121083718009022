import React from 'react'
import { generateActiveIcon } from 'components/Table/helpers'
import { mdy } from './../../util/dates'
import Img from 'react-cool-img'

export const columns = [
	{
		text: 'Imagen',
		dataField: 'thumbnail.path',
		formatter: (cell) => <Img src={cell} alt="" width="100" />,
		sort: true
	},
	{
		dataField: 'title',
		text: 'Título',
		sort: true
	},
	{
		dataField: 'description',
		text: 'Descripción',
		sort: true
	},
	{
		dataField: 'updatedAt',
		text: 'Última modificación',
		sort: true,
		formatter: mdy
	},
	{
		dataField: 'createdAt',
		text: 'Creado',
		sort: true,
		formatter: mdy
	},
	{
		dataField: 'active',
		text: 'Activo',
		formatter: (cell) => generateActiveIcon(cell),
		sort: true
	}
]
