import React, { Component } from 'react'
import { connect } from 'react-redux'
import { productActions } from './product.redux'
import ProductForm from './ProductForm'
import Layout from 'components/Layout/Layout'

class ProductCreate extends Component {
	componentDidMount() {
		this.props.loadProductOptions()
	}

	submit = (values) => {
		this.props.createProduct(values)
	}

	render() {
		return (
			<Layout>
				<ProductForm {...this.props} label="Agregar" button="add" onSubmit={this.submit} />
			</Layout>
		)
	}
}

const mapStateToProps = ({ product }) => ({ product })
const mapDispatchToProps = {
	createProduct: productActions.createProductRequest,
	loadProductOptions: productActions.loadProductOptionsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCreate)
