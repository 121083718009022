import React from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { Row, Col, Table } from 'reactstrap'
import Img from 'react-cool-img'
import { Form, reduxForm, reset } from 'redux-form'
import { RFInput } from 'components/Inputs/RFFields'
import { required, maxLength, substring } from 'util/form'
import userThumbnail from 'assets/images/conversation.svg'
import { reviewsActions } from 'modules/Reviews/reviews.redux'

const maxLength280 = maxLength(280)
const limit280 = substring(0, 280)

function ReviewBox({ handleSubmit, createReview, submitting, pristine, valid, ...props }) {
	const onSubmit = ({ review }) => {
		createReview({ review })
		props.reset()
	}

	return (
		<Form onSubmit={handleSubmit(onSubmit)} className="p-3 box-review">
			<Row>
				<Col lg="10">
					<RFInput
						label="Agregar reseña"
						name="review"
						type="text"
						validate={[ required, maxLength280 ]}
						normalize={limit280}
					/>
				</Col>
				<Col lg="2">
					<button className="btn-review" block disabled={submitting || pristine || !valid}>
						Publicar
					</button>
				</Col>
			</Row>
		</Form>
	)
}

export default connect(null, {
	reset,
	createReview: reviewsActions.createProductReviewRequest
})(reduxForm({ form: 'login', enableReinitialize: true })(ReviewBox))
