import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userActions } from './user.redux'
import Layout from 'components/Layout/Layout'
import UserForm from './UserForm'

class UserCreate extends Component {
	submit = (values) => {
		this.props.createUser(values)
	}

	render() {
		return (
			<Layout>
				<UserForm {...this.props} label="Agregar" button="add" onSubmit={this.submit} />
			</Layout>
		)
	}
}

const mapStateToProps = ({ user }) => ({ user })
const mapDispatchToProps = {
	createUser: userActions.createUserRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
