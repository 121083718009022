/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'
import FormMailer from './Forms/FormMailer'
import { configurationActions as actions } from './../Configuration/configuration.redux'

function InitialConfigurationWizard({ createConfiguration, ...props }) {
  const history = useHistory()
  const isConfigured = useSelector(state => state.configuration.public.configured)

  useEffect(() => {
    if (isConfigured) history.push('/')
    document.body.style.background = 'whitesmoke'
    return () => {
      document.body.style.background = 'white'
    }
  }, [isConfigured])

  const onSubmit = values => {
    createConfiguration(values)
  }

  return (
    <Fragment>
      <FormMailer label='Configuración de envío de correos electrónicos' onSubmit={onSubmit} />
    </Fragment>
  )
}

const mapDispatchToProps = { createConfiguration: actions.createConfigurationRequest }
export default connect(null, mapDispatchToProps)(InitialConfigurationWizard)
