import React from 'react'
import { generateActiveIcon } from 'components/Table/helpers'
import { mdy } from './../../util/dates'
import Img from 'react-cool-img'

export const columns = [
	{
		dataField: 'thumbnail.path',
		text: 'Producto',
		formatter: (cell) => <Img src={cell} alt="" width="100" />,
		sort: true
	},
	{
		dataField: 'name',
		text: 'Nombre',
		sort: true
	},
	{
		dataField: 'price',
		text: 'Puntos',
		sort: true
	},
	{
		dataField: 'stock',
		text: 'Stock',
		sort: true
	},
	{
		dataField: 'updatedAt',
		text: 'Última modificación',
		sort: true,
		formatter: mdy
	},
	{
		dataField: 'createdAt',
		text: 'Creado',
		sort: true,
		formatter: mdy
	},
	{
		dataField: 'active',
		text: 'Activo',
		formatter: (cell) => generateActiveIcon(cell),
		sort: true
	}
]
