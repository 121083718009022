import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
	{
		loadAllOrderRequest: [ 'payload' ],
		loadAllOrderSuccess: [ 'payload' ],
		loadAllOrderFailure: [ 'payload' ],

		loadOrderByUserRequest: [ 'payload' ],
		loadOrderByUserSuccess: [ 'payload' ],
		loadOrderByUserFailure: [ 'payload' ],

		loadOrderRequest: [ 'payload' ],
		loadOrderSuccess: [ 'payload' ],
		loadOrderFailure: [ 'payload' ],

		createOrderRequest: [ 'payload' ],
		createOrderSuccess: [ 'payload' ],
		createOrderFailure: [ 'payload' ],

		editOrderRequest: [ 'payload' ],
		editOrderSuccess: [ 'payload' ],
		editOrderFailure: [ 'payload' ],

		updateOrderRequest: [ 'payload' ],
		updateOrderSuccess: [ 'payload' ],
		updateOrderFailure: [ 'payload' ],

		toggleOrderRequest: [ 'payload' ],
		toggleOrderSuccess: [ 'payload' ],
		toggleOrderFailure: [ 'payload' ],

		deleteOrderRequest: [ 'payload' ],
		deleteOrderSuccess: [ 'payload' ],
		deleteOrderFailure: [ 'payload' ],

		loadOrderOptionsRequest: [ 'payload' ],
		loadOrderOptionsSuccess: [ 'payload' ],
		loadOrderOptionsFailure: [ 'payload' ]
	},
	{}
)

const initialState = {
	list: [],
	view: {},
	edit: {},
	userList: [],
	error: {},
	loading: false
}

export function getOrderByUser(state, action) {
	return { ...state, userList: { ...action.payload } }
}

export const HANDLERS = {
	[Types.LOAD_ALL_ORDER_SUCCESS]: handlers.loadAllSuccess,
	[Types.LOAD_ORDER_BY_USER_SUCCESS]: getOrderByUser,
	[Types.LOAD_ORDER_SUCCESS]: handlers.loadSuccess,
	[Types.CREATE_ORDER_SUCCESS]: handlers.createSuccess,
	[Types.EDIT_ORDER_SUCCESS]: handlers.editSuccess,
	[Types.UPDATE_ORDER_SUCCESS]: handlers.updateSuccess,
	[Types.TOGGLE_ORDER_SUCCESS]: handlers.toggleSuccess,
	[Types.DELETE_ORDER_SUCCESS]: handlers.deleteSuccess
}

export const orderTypes = Types
export const orderActions = Creators
export const order = createReducer(initialState, HANDLERS)
