import React, { useEffect } from 'react'
import Layout from 'components/Layout/Layout'
import DataTable from 'components/Table/Table'
import { columns } from './point.helpers'
import TableContainer from 'components/Container/TableContainer'
import { connect } from 'react-redux'
import { pointActions } from './point.redux'
import { useExportSelected } from 'hooks/useExportSelected'

function PointList(props) {
  const [selectRow, handleExport] = useExportSelected('Usuarios', '/api/load/export')

  useEffect(() => {
    props.loadAllPoint()
  }, [])

  return (
    <Layout>
      <TableContainer title='Puntos' subtitle='Lista'>
        <DataTable
          {...props}
          data={props.points}
          structure={columns}
          onExport={handleExport}
          selectRow={selectRow}
          selected={selectRow.selected}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = ({ point }) => ({ points: point.list })
const mapDispatchToProps = {
  loadAllPoint: pointActions.loadAllPointRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PointList)
