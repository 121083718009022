import React from 'react'
import SearchBar from 'components/SearchBar/SearchBar'
import BannerShopProducts from 'components/Banners/BannerShopProducts'
import ProductsGallery from 'components/Products/ProductsGallery'
import Public from 'components/Layout/Layout'

function Shop() {
	return (
		<Public fluid admin={false}>
			<SearchBar />
			<BannerShopProducts text="Tienda" />
			<ProductsGallery />
		</Public>
	)
}

export default Shop
