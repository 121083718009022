import React from 'react'
import BannerShop from '../Banners/BannerShop'

function Hero() {
	return (
		<section className="hero">
			<div className="container">
				<div className="row">
					<BannerShop />
				</div>
			</div>
		</section>
	)
}

export default Hero
