/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import DataTable from 'components/Table/Table'
import Layout from 'components/Layout/Layout'
import TableContainer from 'components/Container/TableContainer'
import { columns } from './user.helpers'
import { userActions } from './user.redux'
import { useExportSelected } from 'hooks/useExportSelected'

function UserList(props) {
  const [selectRow, handleExport] = useExportSelected('Usuarios', '/api/users/export')

  useEffect(() => {
    props.loadAllUser()
    props.loadUserOptions()
  }, [])

  const rowEvents = {
    onClick: (e, row) => {
      const url = `/dashboard/user/view/${row._id}`
      props.history.push(url)
    }
  }

  return (
    <Layout>
      <TableContainer
        title='Usuarios'
        subtitle='Lista'
        route='/dashboard/user/create'
        mbodule='Usuarios'>
        <DataTable
          rowEvents={rowEvents}
          data={props.users}
          structure={columns}
          onExport={handleExport}
          selectRow={selectRow}
          selected={selectRow.selected}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = ({ user }) => ({ users: user.list })
const mapDispatchToProps = {
  loadAllUser: userActions.loadAllUserRequest,
  toggleUser: userActions.toggleUserRequest,
  loadUserOptions: userActions.loadUserOptionsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
