import React, { Component } from 'react'
import { connect } from 'react-redux'
import { categoryActions } from './category.redux'
import Layout from 'components/Layout/Layout'
import { Row, Col } from 'reactstrap'
import Img from 'react-cool-img'
import { mdy } from './../../util/dates'
import { isEmpty } from './../../util/crud'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'

class CategoryView extends Component {
	componentDidMount() {
		const { id } = this.props.match.params
		this.props.loadCategoryRequest({ id })
	}

	componentWillUnmount() {
		this.props.loadCategorySuccess()
	}

	render() {
		const { view } = this.props.category
		return (
			<Layout>
				<TableContainer
					title="Categoría"
					subtitle="Información del registro"
					toggle={this.props.toggleCategoryRequest}
					view={view}
				>
					{!isEmpty(view) && (
						<Row id="view-category">
							<Col lg="6">
								<ul>
									<ListItem label="Título" value={view.title} />
									<ListItem label="Descripción" value={view.description} />
									<ListItem label="Activo" value={view.active} />
									<ListItem label="Creado" value={mdy(view.createdAt)} />
								</ul>
							</Col>
							<Col lg="6" className="d-flex flex-row align-items-center justify-content-center">
								<Img src={view.thumbnail.path} alt="" width="150" />
							</Col>
						</Row>
					)}
				</TableContainer>
			</Layout>
		)
	}
}

const mapStateToProps = ({ category }) => ({ category })
export default connect(mapStateToProps, categoryActions)(CategoryView)
