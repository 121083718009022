import { createReducer, createActions } from 'reduxsauce'

const { Creators, Types } = createActions(
	{
		alertMessageSuccess: [ 'payload' ],
		alertMessageAdd: [ 'payload' ],
		alertMessageWish: [ 'payload' ],
		alertMessageWarningCustom: [ 'payload' ],
		alertMessageWarning: [ 'payload' ],
		alertMessageError: [ 'payload' ],
		alertMessageEmpty: [ 'payload' ],
		alertMessageEmptyGoBack: [ 'payload' ],
		alertPromptShow: [ 'payload' ],
		alertPromptHide: [ 'payload' ],
		alertPromptTrackingShow: [ 'payload' ],
		alertPromptTrackingHide: [ 'payload' ],
		alertClear: [ 'payload' ]
	},
	{}
)

const initialState = {
	justification: '',
	success: '',
	add: '',
	wish: '',
	warningCustom: '',
	warning: '',
	error: '',
	prompt: false,
	propmtTracking: false
}

export const HANDLERS = {
	[Types.ALERT_MESSAGE_SUCCESS]: (state, action) => ({
		...initialState,
		success: action.payload
	}),
	[Types.ALERT_MESSAGE_ADD]: (state, action) => ({
		...initialState,
		add: action.payload
	}),
	[Types.ALERT_MESSAGE_WISH]: (state, action) => ({
		...initialState,
		wish: action.payload
	}),
	[Types.ALERT_MESSAGE_WARNING_CUSTOM]: (state, action) => ({
		...initialState,
		warningCustom: action.payload
	}),
	[Types.ALERT_MESSAGE_WARNING]: (state, action) => ({
		...initialState,
		warning: action.payload
	}),
	[Types.ALERT_MESSAGE_ERROR]: (state, action) => ({
		...initialState,
		error: action.payload
	}),
	[Types.ALERT_MESSAGE_EMPTY]: (state, action) => ({
		...initialState,
		isEmpty: action.payload
	}),
	[Types.ALERT_MESSAGE_EMPTY_GO_BACK]: (state, action) => ({
		...initialState,
		isEmptyGoBack: action.payload
	}),
	[Types.ALERT_PROMPT_SHOW]: (state, action) => ({
		...initialState,
		prompt: true
	}),
	[Types.ALERT_PROMPT_HIDE]: (state, action) => ({
		...initialState,
		prompt: false,
		justification: action.payload
	}),
	[Types.ALERT_PROMPT_TRACKING_SHOW]: (state, action) => ({
		...initialState,
		propmtTracking: true
	}),
	[Types.ALERT_PROMPT_TRACKING_HIDE]: (state, action) => ({
		...initialState,
		propmtTracking: false,
		justification: action.payload
	}),
	[Types.ALERT_CLEAR]: (state, action) => ({ ...initialState })
}

export const alertTypes = Types
export const alertActions = Creators
export const alert = createReducer(initialState, HANDLERS)
