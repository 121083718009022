import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    loadAllUserRequest: ['payload'],
    loadAllUserSuccess: ['payload'],
    loadAllUserFailure: ['payload'],

    loadUserRequest: ['payload'],
    loadUserSuccess: ['payload'],
    loadUserFailure: ['payload'],

    loadUserOptionsRequest: ['payload'],
    loadUserOptionsSuccess: ['payload'],
    loadUserOptionsFailure: ['payload'],

    createUserRequest: ['payload'],
    createUserSuccess: ['payload'],
    createUserFailure: ['payload'],

    editUserRequest: ['payload'],
    editUserSuccess: ['payload'],
    editUserFailure: ['payload'],

    updateUserRequest: ['payload'],
    updateUserSuccess: ['payload'],
    updateUserFailure: ['payload'],

    toggleUserRequest: ['payload'],
    toggleUserSuccess: ['payload'],
    toggleUserFailure: ['payload'],

    deleteUserRequest: ['payload'],
    deleteUserSuccess: ['payload'],
    deleteUserFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {},
  options: {
    modules: []
  }
}

export function loadUserOptions(state, action) {
  return { ...state, options: { ...action.payload } }
}

export const HANDLERS = {
  [Types.LOAD_ALL_USER_SUCCESS]: handlers.loadAllSuccess,
  [Types.LOAD_USER_SUCCESS]: handlers.loadSuccess,
  [Types.CREATE_USER_SUCCESS]: handlers.createSuccess,
  [Types.EDIT_USER_SUCCESS]: handlers.editSuccess,
  [Types.UPDATE_USER_SUCCESS]: handlers.updateSuccess,
  [Types.TOGGLE_USER_SUCCESS]: handlers.toggleSuccess,
  [Types.DELETE_USER_SUCCESS]: handlers.deleteSuccess,
  [Types.LOAD_USER_OPTIONS_SUCCESS]: loadUserOptions
}

export const userTypes = Types
export const userActions = Creators
export const user = createReducer(initialState, HANDLERS)
