import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import LoadForm from './LoadForm'
import { loadActions } from './load.redux'

class LoadCreate extends Component {
  submit = values => {
    this.props.createLoad(values)
  }

  render() {
    return (
      <Layout>
        <LoadForm {...this.props} label='Agregar' button='add' onSubmit={this.submit} />
      </Layout>
    )
  }
}

const mapStateToProps = ({ load }) => ({ load })
const mapDispatchToProps = {
  createLoad: loadActions.createLoadRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadCreate)
