import {
  addProductShoppingCart,
  getTotalProducts,
  removeItemShoppingCart,
  removeProductShoppingCart
} from './shoppingCart.helpers'

// Types
export const ADD_PRODUCT_TO_SHOPPINGCART_REQUEST = 'ADD_PRODUCT_TO_SHOPPINGCART_REQUEST'
export const ADD_PRODUCT_TO_SHOPPINGCART_SUCCESS = 'ADD_PRODUCT_TO_SHOPPINGCART_SUCCESS'

export const REMOVE_ITEM_FROM_SHOPPINGCART_REQUEST = 'REMOVE_ITEM_FROM_SHOPPINGCART_REQUEST'
export const REMOVE_ITEM_FROM_SHOPPINGCART_SUCCESS = 'REMOVE_ITEM_FROM_SHOPPINGCART_SUCCESS'

export const REMOVE_PRODUCT_FROM_SHOPPINGCART_REQUEST = 'REMOVE_PRODUCT_TO_SHOPPINGCART_REQUEST'
export const REMOVE_PRODUCT_FROM_SHOPPINGCART_SUCCESS = 'REMOVE_PRODUCT_TO_SHOPPINGCART_SUCCESS'

export const GET_SHOPPING_CART_REQUEST = 'GET_SHOPPING_CART_REQUEST'
export const GET_SHOPPING_CART_REQUEST_SUCCESS = 'GET_SHOPPING_CART_REQUEST_SUCCESS'

export const RESET = 'RESET'

// Actions
export const addProductToShoppingCart = product => ({
  type: ADD_PRODUCT_TO_SHOPPINGCART_REQUEST,
  product
})

export const addProductToShoppingCartSuccess = product => ({
  type: ADD_PRODUCT_TO_SHOPPINGCART_SUCCESS,
  product
})

export const removeItemFromShoppingCart = shoppingCartId => ({
  type: REMOVE_ITEM_FROM_SHOPPINGCART_REQUEST,
  shoppingCartId
})

export const removeItemFromShoppingCartSuccess = product => ({
  type: REMOVE_ITEM_FROM_SHOPPINGCART_SUCCESS,
  product
})

export const removeProductFromShoppingCartSuccess = productId => ({
  type: REMOVE_PRODUCT_FROM_SHOPPINGCART_SUCCESS,
  productId
})

export const removeProductFromShoppingCart = (shoppingCartId, productId) => ({
  type: REMOVE_PRODUCT_FROM_SHOPPINGCART_REQUEST,
  shoppingCartId,
  productId
})

export const getShoppingCart = () => ({
  type: GET_SHOPPING_CART_REQUEST
})

export const addShoppingCartSuccess = (shoppingCartId, product) => ({
  type: ADD_PRODUCT_TO_SHOPPINGCART_SUCCESS,
  shoppingCartId,
  product
})

export const reset = () => ({
  type: RESET
})

// Reducer
const initialState = {
  products: [],
  discount: 0,
  total: 0
}

export function shoppingCart(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT_TO_SHOPPINGCART_SUCCESS:
    case GET_SHOPPING_CART_REQUEST_SUCCESS:
      return {
        products: addProductShoppingCart(state.products, action.product, action.shoppingCartId),
        discount: 0,
        total: getTotalProducts(addProductShoppingCart(state.products, action.product))
      }
    case REMOVE_ITEM_FROM_SHOPPINGCART_SUCCESS:
      return {
        products: removeItemShoppingCart(state.products, action.shoppingCartId),
        discount: 0,
        total: getTotalProducts(removeItemShoppingCart(state.products, action.shoppingCartId))
      }
    case REMOVE_PRODUCT_FROM_SHOPPINGCART_REQUEST:
      return {
        products: removeProductShoppingCart(state.products, action.productId),
        discount: 0,
        total: getTotalProducts(removeProductShoppingCart(state.products, action.productId))
      }
    case RESET:
      return initialState

    default:
      return state
  }
}
