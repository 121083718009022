import axios from 'axios'
import { push } from 'connected-react-router'
import swal from 'sweetalert'
import { all, takeLatest, call, put, take, select } from 'redux-saga/effects'
import { alertActions, alertTypes } from '././../Alert/alert.redux'
import { userActions, userTypes } from './user.redux'
import { isEmpty } from './../../util/crud'
import { actions } from 'modules/Auth/auth.redux'

/**
 * Display the users list
 * @returns {iterator}
 */
export function* loadAllUser() {
  try {
    const url = '/api/users/'
    const { data: users } = yield call(axios.get, url)
    yield put(userActions.loadAllUserSuccess(users))
    if (isEmpty(users)) {
      yield put(alertActions.alertMessageEmptyGoBack('user', '/dashboard/user/create'))
    }
  } catch (error) {
    yield put(userActions.loadAllUserFailure(error))
    console.log(error)
  }
}

/**
 * Display a single user record
 * @param   {object}   action.payload Data to take the id of the requested user
 * @returns {iterator}
 */
export function* loadUser({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/users/${id}`
    const { data: user } = yield call(axios.get, url)
    yield put(userActions.loadUserSuccess(user))
  } catch (error) {
    yield put(userActions.loadUserFailure(error))
    console.log(error)
  }
}

/**
 * Create an user record
 * @param   {object}   action.payload Data to create an user record
 * @returns {iterator}
 */
export function* createUser({ payload }) {
  try {
    const url = '/api/users/'
    const load = { ...payload, role: payload.role.value }
    // Make the POST request
    const { data } = yield call(axios.post, url, load)
    // Add new document to the list
    yield put(userActions.createUserSuccess(data))
    yield swal('Usuario creado', `${data.email}`, 'success')
    // Return the user to the list
    yield put(push('/dashboard/user/list'))
  } catch (error) {
    yield put(userActions.createUserFailure(error))
    console.log(error)
  }
}

/**
 * Load the information of a single user record to edit it
 * @param   {object}   action.payload Data to take the id of the requested user
 * @returns {iterator}
 */
export function* editUser({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/users/edit/${id}`
    const { data: user } = yield call(axios.get, url)
    const roles = ['Administrador total', 'Administrador restringido', 'Usuario']
    const response = { ...user, role: { value: user.role, label: roles[user.role] } }
    yield put(userActions.editUserSuccess(response))
  } catch (error) {
    yield put(userActions.editUserFailure(error))
    console.log(error)
  }
}

/**
 * Update an user record
 * @param   {object}   action.payload Data to update an user record
 * @returns {iterator}
 */
export function* updateUser({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/users/${id}`

    const historical = { cause: 'Actualización', description: prompt.payload }
    const load = values.role ? { ...values, role: values.role.value } : values
    const data = { payload: load, historical }
    const { data: updatedUser } = yield call(axios.put, url, data)
    yield put(userActions.updateUserSuccess(updatedUser))

    // Success notification and return the user to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))

    yield put(push('/dashboard/user/list'))

    const getUserId = state => state.auth.user.id
    const user = yield select(u => getUserId(u))

    // If the rol has changed, logout the user
    if (updatedUser._id === user && values.role !== updatedUser.role) {
      yield put(actions.logoutRequest(user))
      yield put(push('/login'))
    }
  } catch (error) {
    yield put(userActions.updateUserFailure(error))
    console.log(error)
  }
}

/**
 * Toggle the active property of an user record
 * @param   {object}   action.payload Data to update an user record
 * @returns {iterator}
 */
export function* toggleUser({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)
    const description = prompt.payload

    // The prompt was closed, stop the flow
    if (isEmpty(description)) return
    const { _id, active } = payload
    const url = `/api/users/${_id}`

    // Make the PUT request
    const historical = { cause: active ? 'Desactivación' : 'Activación', description }
    const { data: updatedUser } = yield call(axios.put, url, {
      payload: { active: !active },
      historical
    })
    yield put(userActions.toggleUserSuccess(updatedUser))

    // Show success notification
    const result = updatedUser.active ? 'activado' : 'desactivado'
    yield put(alertActions.alertMessageSuccess(`Registro ${result}`))

    yield put(push('/dashboard/user/list'))
  } catch (error) {
    yield put(userActions.toggleUserFailure(error))
    console.log(error)
  }
}

/**
 * Validate an user record
 * @param     values
 * @returns
 */
export async function validateUser(values) {
  const url = '/api/users/validate'
  const { data: result } = await axios.post(url, values)
  if (!isEmpty(result.errors)) throw result.errors
}

export function* loadUserOptions() {
  try {
    const { data: modules } = yield call(axios.get, '/api/modules')
    const payload = { modules }
    yield put(userActions.loadUserOptionsSuccess(payload))
  } catch (error) {
    yield put(userActions.loadUserOptionsFailure(error))
    console.log(error)
  }
}

export function* userSagas() {
  yield all([
    takeLatest(userTypes.LOAD_ALL_USER_REQUEST, loadAllUser),
    takeLatest(userTypes.LOAD_USER_REQUEST, loadUser),
    takeLatest(userTypes.CREATE_USER_REQUEST, createUser),
    takeLatest(userTypes.EDIT_USER_REQUEST, editUser),
    takeLatest(userTypes.UPDATE_USER_REQUEST, updateUser),
    takeLatest(userTypes.TOGGLE_USER_REQUEST, toggleUser),
    takeLatest(userTypes.LOAD_USER_OPTIONS_REQUEST, loadUserOptions)
  ])
}
