import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Navbar from 'components/Navbar/Navbar'
import Acl from 'components/Acl/Acl'
import { connect } from 'react-redux'
import { loadConfiguration } from 'modules/Configuration/configuration.sagas'
import { configurationActions } from 'modules/Configuration/configuration.redux'

function Menu({ role, configuration }) {
  useEffect(() => {
    loadConfiguration()
  }, [])
  return (
    <div>
      <Navbar admin />
      <div className='contain' style={{ display: 'flex', alignItems: 'center', height: '65vh' }}>
        <div className='main clearfix' style={{ display: 'flex', alignItems: 'center' }}>
          <nav id='menu' className='menu w-100'>
            <button
              type='button'
              id='menutoggle'
              className='menutoogle active'
              aria-hidden='true'>
              <i aria-hidden='true' className='icon-menu' />
              Menú
            </button>
            <ul>
              <Acl module='category' action='read'>
                <li>
                  <Link to='/dashboard/category/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-check' />
                    </span>
                    <span>Categorías</span>
                  </Link>
                </li>
              </Acl>
              <Acl module='product' action='read'>
                <li>
                  <Link to='/dashboard/product/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-th' />
                    </span>
                    <span>Productos</span>
                  </Link>
                </li>
              </Acl>
              <Acl module='order' action='read'>
                <li>
                  <Link to='/dashboard/order/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-list' />
                    </span>
                    <span>Ordenes</span>
                  </Link>
                </li>
              </Acl>
              <Acl module='load' action='read'>
                <li>
                  <Link to='/dashboard/load/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-cloud-upload' />
                    </span>
                    <span>Puntos</span>
                  </Link>
                </li>
              </Acl>
              {/* <Acl module='userPoints' action='read'> */}
              <li>
                <Link to='/dashboard/userPoints/list'>
                  <span className='img'>
                    <i aria-hidden='true' className='icon-user' />
                  </span>
                  <span>Puntos Usuarios</span>
                </Link>
              </li>
              {/* </Acl> */}
              <li>
                <Link to='/dashboard/pointsReport'>
                  <span className='img'>
                    <i aria-hidden='true' className='icon-file-o' />
                  </span>
                  <span>Reporte</span>
                </Link>
              </li>
              <Acl module='user' action='read'>
                <li>
                  <Link to='/dashboard/user/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-group' />
                    </span>
                    <span>Usuarios</span>
                  </Link>
                </li>
              </Acl>
              {role === 0 && (
                <li>
                  {configuration === null ? (
                    <Link to='/dashboard/configuration/create'>
                      <span className='img'>
                        <i aria-hidden='true' className='icon-cog' />
                      </span>
                      <span>Configuración</span>
                    </Link>
                  ) : (
                    <Link to='/dashboard/configuration/edit'>
                      <span className='img'>
                        <i aria-hidden='true' className='icon-cog' />
                      </span>
                      <span>Configuración</span>
                    </Link>
                  )}
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, configuration }) => ({
  role: auth.user.role,
  configuration: configuration.public.configured
})

const mapDispatchToProps = {
  loadConfiguration: configurationActions.publicLoadConfigurationRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
