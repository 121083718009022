import React from 'react'
import { Card, CardHeader, CardFooter, CardBody, CardTitle } from 'reactstrap'
import logo from 'assets/images/Softtek.png'
import './initial-configuration.css'

function FormWrapper({ title, children, ...props }) {
  const year = new Date().getFullYear()
  return (
    <div className='initial-configuration'>
      <div className='form-wrapper' {...props}>
        <Card>
          <CardHeader tag='center' className='form-header'>
            {title}
          </CardHeader>
          <CardBody className='form-body'>
            <CardTitle>
              <img src={logo} height='80' alt='Softtek' id='logo' />
            </CardTitle>
            <div>{children}</div>
          </CardBody>
          <CardFooter tag='center' className='form-footer'>
            &copy; Valores Corporativos Softtek S.A. de C.V. {year}
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}

export default FormWrapper
