import { generateActiveIcon } from 'components/Table/helpers'
import { mdy } from './../../util/dates'

export const columns = [
  { dataField: 'user.email', text: 'Correo', sort: true },
  { dataField: 'user.fullName', text: 'Nombre', sort: true },
  { dataField: 'description', text: 'Description', sort: true },
  { dataField: 'value', text: 'Puntos', sort: true },
  { dataField: 'createdAt', text: 'Creado', sort: true, formatter: mdy },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]

export const columnsReport = [
  { dataField: 'user.email', text: 'Correo', sort: true },
  { dataField: 'user.fullName', text: 'Nombre', sort: true },
  { dataField: 'load.title', text: 'Título', sort: true },
  { dataField: 'description', text: 'Descripción', sort: true },
  { dataField: 'points', text: 'Puntos', sort: true },
  { dataField: 'createdAt', text: 'Creado', sort: true, formatter: mdy },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
