import React, { useState } from 'react'
import { productActions } from 'modules/Product/product.redux'
import { connect } from 'react-redux'
import { isEmpty } from 'util/crud'

function SearchForm({ searchProducts }) {
  const [name, setName] = useState('')

  const handleSubmit = evt => {
    evt.preventDefault()
    if (!isEmpty(name)) {
      searchProducts({ name })
    } else {
      return
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      {/* <div className="hero-search-categories" /> */}
      <input
        type='text'
        value={name}
        onChange={e => setName(e.target.value)}
        placeholder='Buscador de Productos'
      />
      <button type='submit' value='Submit' className='site-btn'>
        Buscar
      </button>
    </form>
  )
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {
  searchProducts: productActions.searchProductRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchForm)
