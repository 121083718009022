export const addProductShoppingCart = (shoppingCartList, product, shoppingCartId) => {
	//Encuentra el id del shopping cart y si es igual
	const check = shoppingCartList.find((i) => i.product.id === product.id)
	//Crea un nuevo producto con las mismas propiedades pero crea un nuevo id de shoppingCart

	if (!check) {
		const newProduct = {
			shoppingCartId,
			product: {
				name: product.name,
				id: product.id,
				price: product.price,
				stock: product.stock,
				category: product.category,
				thumbnail: product.thumbnail
			},
			quantity: 1,
			total: product.price
		}
		//Regresa el nuevo producto dentro de la lista actualizada de shopping cart
		return [ ...shoppingCartList, newProduct ].sort((a, b) => a.id - b.id)
	}

	const quantity = check.quantity + 1
	const total = check.total + check.product.price
	const updateProduct = {
		shoppingCartId,
		product: {
			name: product.name,
			id: product.id,
			price: product.price,
			stock: product.stock,
			category: product.category,
			thumbnail: product.thumbnail
		},
		quantity,
		total
	}

	const newShoppingCartList = shoppingCartList.filter((row) => row.product.id !== check.product.id)
	return [ ...newShoppingCartList, updateProduct ].sort((a, b) => a.product.id - b.product.id)
}

export const getTotalProducts = (products) => products.reduce((a, c) => a + c.total, 0)

export const removeItemShoppingCart = (shoppingCartList, id) => {
	const check = shoppingCartList.find((i) => i.shoppingCartId === id)
	if (check.quantity > 1) {
		const quantity = check.quantity - 1
		const total = check.total - check.product.price
		const updateItemShoppingCart = {
			...check,
			quantity,
			total
		}
		//Regresa una nueva lista exluyendo los elementos que le asignamos a la condicion
		const newShoppingCartList = shoppingCartList.filter((row) => row.product.id !== check.product.id)
		//Checa si es negativo, cero o positivo y lo usa para saber en que orden estaran
		return [ ...newShoppingCartList, updateItemShoppingCart ].sort((a, b) => a.id - b.id)
	}

	return shoppingCartList
		.filter((row) => row.product.id !== check.product.id)
		.sort((a, b) => a.product.id - b.product.id)
}

export const removeProductShoppingCart = (shoppingCartList, id) =>
	shoppingCartList.filter((row) => row.product.id !== id).sort((a, b) => a.product.id - b.product.id)
