import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { orderActions } from 'modules/Order/order.redux'
import ShoppingCartItem from './ShoppingCartItem'

function ShoppingCart({ shoppingCart, createOrder, shoppingCartId }) {
	const cart = shoppingCart.products.length

	return cart > 0 ? (
		<section className="shoping-cart spad">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="shoping-cart-table">
							<table>
								<thead>
									<tr>
										<th style={{ paddingLeft: '3%' }}>Producto</th>
										<th>Descripción</th>
										<th>Puntos</th>
										<th style={{ paddingLeft: '3%' }}>Cantidad</th>
										<th style={{ paddingLeft: '1%' }}>Total</th>
										<th style={{ paddingLeft: '3%' }}>Eliminar</th>
									</tr>
								</thead>
								<tbody>
									{shoppingCart.products.map((p) => <ShoppingCartItem key={p.product.id} {...p} />)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div className="shoping-cart-btns">
							<Link to="/shop" className="primary-btn cart-btn">
								CONTINUAR COMPRANDO
							</Link>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="shoping-checkout">
							<h4>Total del Carrito</h4>
							<ul style={{ padding: 0 }}>
								<li>
									Subtotal <span> {shoppingCart.total} Puntos</span>
								</li>
								<li>
									Total <span> {shoppingCart.total} Puntos</span>
								</li>
							</ul>
							<Link
								to="/shop"
								onClick={() => createOrder(shoppingCartId)}
								className="primary-btn cart-btn"
							>
								PROCEDER A COMPRAR
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	) : (
		<div className="alert-empty">
			<div className="alert alert-info" role="alert">
				<h4 className="alert-heading">Tu carrito esta vacio</h4>
				<p className="mb-0">
					Te invitamos a ver nuestros{' '}
					<a style={{ color: '#0c5460', fontWeight: 'bold', textDecoration: 'underline' }} href="/shop">
						{' '}
						productos{' '}
					</a>
				</p>
			</div>
		</div>
	)
}

const mapStateToProps = ({ shoppingCart, order }) => ({ shoppingCart, order })
const mapDispatchToProps = {
	createOrder: orderActions.createOrderRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)
