import axios from 'axios'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import { isEmpty } from '../../util/crud'
import { alertActions } from 'modules/Alert/alert.redux'
import { pointActions, pointTypes } from './point.redux'
import * as handlers from './../../redux/helpers/handlers'

// Load points records
export function* loadAllPointSaga() {
  try {
    const { data: points } = yield call(axios.get, '/api/points/')
    if (isEmpty(points)) {
      yield put(alertActions.alertMessageEmpty('load', '/dashboard/load/create'))
    }
    yield put(pointActions.loadAllPointSuccess(points))
  } catch (error) {
    yield put(alertActions.alertMessageError('Hubo un problema al cargar datos'))
  }
}

// Load points records
export function* loadAllPointsSaga() {
  try {
    const { data: points } = yield call(axios.get, '/api/points/getPoints')
    if (isEmpty(points)) {
      yield put(alertActions.alertMessageEmpty('load', '/dashboard/load/create'))
    }
    yield put(pointActions.loadAllPointsSuccess(points))
  } catch (error) {
    yield put(alertActions.alertMessageError('Hubo un problema al cargar datos'))
  }
}

// Load points report
export function* loadReportSaga() {
  try {
    const { data: report } = yield call(axios.get, '/api/points/reportPoints')
    yield put(pointActions.loadReportPointsSuccess(report))
  } catch (error) {
    yield put(alertActions.alertMessageError('Hubo un problema al cargar datos'))
  }
}

// Load record point de cada registro de puntos
export function* loadPointSaga({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/points/${id}`
    const { data: user } = yield call(axios.get, url)
    yield put(pointActions.loadPointSuccess(user))
  } catch (error) {
    yield put(alertActions.alertMessageError('Hubo un problema al cargar datos'))
  }
}

// Load points de cada usuario
export function* loadPointUserSaga(action) {
  try {
    const user = yield select(state => handlers.getUserId(state))
    const url = `/api/points/getPointsByUser`
    const { data: userPoints } = yield call(axios.post, url, { user })
    const points = userPoints.map(p => ({ pointUser: p.points, operation: p.operation }))
    const totalPoints = points.reduce((a, v) => (a = a + v.pointUser), 0)
    yield put(pointActions.loadPointByUserSuccess(totalPoints))
  } catch (error) {
    yield put(alertActions.alertMessageError('Hubo un problema al cargar datos'))
  }
}

export function* pointSagas() {
  yield all([
    takeLatest(pointTypes.LOAD_ALL_POINT_REQUEST, loadAllPointSaga),
    takeLatest(pointTypes.LOAD_ALL_POINTS_REQUEST, loadAllPointsSaga),
    takeLatest(pointTypes.LOAD_POINT_REQUEST, loadPointSaga),
    takeLatest(pointTypes.LOAD_POINT_BY_USER_REQUEST, loadPointUserSaga),
    takeLatest(pointTypes.LOAD_REPORT_POINTS_REQUEST, loadReportSaga)
  ])
}
