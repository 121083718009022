import React, { Fragment } from 'react'
import { reduxForm, FieldArray } from 'redux-form'
import FormContainer from 'components/Container/FormContainer'
import { Form, Row, Col, Container } from 'reactstrap'
import { withForm } from 'util/withForm'
import { RFInput, RFSelect } from 'components/Inputs/RFFields'
import { required } from 'util/form'
import { connect } from 'react-redux'

const limitQuantity = max => value => value < max ? value : max

export const renderProducts = ({ fields, meta: { error } }) => {
  return (
    <Container>
      {fields.map((product, index) => {
        return (
          <Fragment key={`product-${index + 1}`}>
            <Row>
              {/* Products (id) */}
              <RFInput type='text' name={`${product}.product`} validate={[required]} hidden />
              <Col md='4'>
                {/* Products (string) */}
                <RFInput
                  type='text'
                  name={`${product}.name`}
                  disabled
                  label='Producto'
                  validate={[required]}
                />
              </Col>
              <Col md='4'>
                {/* Products (number) */}
                <RFInput
                  type='text'
                  name={`${product}.price`}
                  disabled
                  label='Puntos'
                  validate={[required]}
                />
              </Col>
              <Col md='4'>
                {/* quantity (number) */}
                <RFInput
                  name={`${product}.quantity`}
                  type='number'
                  label='Cantidad'
                  validate={[required]}
                  normalize={limitQuantity(`${product}.quantity`)}
                />
              </Col>
            </Row>
          </Fragment>
        )
      })}
      <p style={{ color: 'red' }}>{error}</p>
    </Container>
  )
}

function OrderForm({ ...props }) {
  const formatOptions = key => option => ({ value: option._id, label: option[key] })
  const statusOptions = [
    { value: 0, label: 'Pendiente' },
    { value: 1, label: 'Listo' },
    { value: 2, label: 'Entregado' },
    { value: 3, label: 'Cancelado' }
  ]

  const { handleSubmit, onCancel, label, button, option, ...others } = props
  const { pristine, valid, submitting } = others

  return (
    <FormContainer
      title='Orden'
      label={label}
      button={button}
      onCancel={onCancel}
      onClick={handleSubmit}
      status={{ pristine, valid, submitting }}>
      <Form>
        <Row>
          <Col lg='6'>
            <RFInput
              name='user'
              label='Usuario'
              validate={[required]}
              formatter={formatOptions('fullName')}
              disabled
            />
          </Col>
          <Col lg='6'>
            <RFSelect
              name='status'
              label='Estatus'
              options={statusOptions}
              validate={[required]}
            />
          </Col>
        </Row>
        <Row>
          <FieldArray name='products' component={renderProducts} validate={[required]} />
        </Row>
      </Form>
    </FormContainer>
  )
}

const mapStateToProps = ({ order }) => ({
  initialValues: {
    ...order.edit
  }
})
const withConnect = connect(mapStateToProps)

export default withConnect(
  withForm(
    reduxForm({
      form: 'order'
    })(OrderForm)
  )
)
