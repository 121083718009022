import axios from 'axios'
import swal from 'sweetalert2'
import { setAuthToken } from 'util/auth'
const request = axios

request.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const { response } = error
    const { data } = response
    const { message, status, logout } = data
    // Interceptamos todos los códigos 401 en llamadas de la API}
    logout &&
      status === 401 &&
      // set timeout swal alert
      setTimeout(() => {
        swal
          .fire({
            title: 'Sin autorización',
            text: 'Vuelve a iniciar sesión, por favor',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
          .then(result => {
            if (result.isConfirmed) {
              setAuthToken(false)
              localStorage.removeItem('auth_token')
              window.location.reload(false)
            }
          })
      }, 2000)
    return Promise.reject(error)
  }
)
