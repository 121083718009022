import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import axios from 'axios'
import { alertActions } from '../../../modules/Alert/alert.redux'
import * as handlers from './../../../redux/helpers/handlers'

import {
  ADD_PRODUCT_TO_SHOPPINGCART_REQUEST,
  REMOVE_ITEM_FROM_SHOPPINGCART_REQUEST,
  REMOVE_PRODUCT_FROM_SHOPPINGCART_REQUEST,
  GET_SHOPPING_CART_REQUEST,
  ADD_PRODUCT_TO_SHOPPINGCART_SUCCESS,
  addShoppingCartSuccess,
  REMOVE_ITEM_FROM_SHOPPINGCART_SUCCESS,
  removeProductFromShoppingCartSuccess
} from './shoppingCart.redux'

export function* addProudctToShoppingCartSaga(action) {
  try {
    //Validación de usuario logeado
    const user = yield select(u => handlers.getUserId(u))
    if (!user) return yield put(alertActions.alertMessageWarning('Por favor iniciar sesión'))
    const { product } = action
    // obtener puntos de usuarios
    const pointsUser = yield select(state => state.point.points)

    // obtener total de shoppingCart
    const total = yield select(state => state.shoppingCart.total)

    // obtener stock de product
    const array = yield select(state => state.shoppingCart.products)
    const quantity = array.map(q => 1 + q.quantity)

    // Obtener stock y producto único para comparar cantidad y stock
    const productName = product.name
    const findProduct = array.filter(e => {
      return e.product.name === productName
    })
    const getQuantity = findProduct.map(q => 1 + q.quantity)
    const getProductStock = findProduct.map(s => s.product.stock)

     //Validacion de stock
     if (getQuantity[0] > getProductStock[0]){
      yield put(alertActions.alertMessageWarningCustom('No hay suficientes productos en stock'))
    } else if (quantity > product.stock || product.stock === 0) {
      yield put(alertActions.alertMessageWarningCustom('No hay suficientes productos en stock'))
    } else if (product.price > pointsUser) {
      // Validacion de puntos suficientes
      yield put(alertActions.alertMessageWarningCustom('No tienes suficientes puntos'))
    } else if (total > pointsUser - product.price) {
      yield put(alertActions.alertMessageWarningCustom('No tienes suficientes puntos'))
    } else {
      //Guardar el registro en la base de datos
      const shoppingCartPost = { user, product: product.id }
      const { data: shoppingCartRow } = yield call(
        axios.post,
        '/api/public/shoppingCart',
        shoppingCartPost
      )

      //se agregara al state
      yield put({
        type: ADD_PRODUCT_TO_SHOPPINGCART_SUCCESS,
        shoppingCartId: shoppingCartRow.id,
        product
      })
      yield put(alertActions.alertMessageAdd('Producto agregado al carrito'))
    }
  } catch (error) {
    yield put(alertActions.alertMessageError('Problema al agregar producto al carrito'))
  }
}
export function* removeItemFromShoppingCartSaga(action) {
  try {
    const shoppingCartId = action.shoppingCartId
    yield call(axios.delete, `/api/public/shoppingCart/${shoppingCartId}`)

    yield put({ type: REMOVE_ITEM_FROM_SHOPPINGCART_SUCCESS, shoppingCartId })

    yield put(alertActions.alertMessageError('Se eliminó un producto del carrito'))
  } catch (error) {
    yield put(alertActions.alertMessageError('Problema eliminando producto de Carrito de Compra'))
  }
}

export function* removeProductFromShoppingCartSaga(action) {
  try {
    const user = yield select(u => handlers.getUserId(u))
    const id = action.productId
    yield call(axios.post, `/api/public/shoppingCart/removeProductFromShoppingCart/${id}`, {
      user
    })
    yield put(removeProductFromShoppingCartSuccess(action.product))
    yield put(alertActions.alertMessageError('Se eliminó el producto del carrito'))
  } catch (error) {
    yield put(alertActions.alertMessageError('Problema eliminando producto de Carrito de Compra'))
  }
}

export function* getShoppingCartByUserSaga(action) {
  try {
    const user = yield select(state => handlers.getUserId(state))
    const { data: shoppingCartList } = yield call(
      axios.post,
      '/api/public/shoppingCart/getShoppingCartByUser',
      {
        user
      }
    )

    yield all(shoppingCartList.map(row => put(addShoppingCartSuccess(row.id, row.product))))
  } catch (error) {
    yield put(alertActions.alertMessageError('Problema obteniendo de Carrito de Compra'))
  }
}

export function* shoppingCartSagas() {
  yield all([
    takeLatest(ADD_PRODUCT_TO_SHOPPINGCART_REQUEST, addProudctToShoppingCartSaga),
    takeLatest(GET_SHOPPING_CART_REQUEST, getShoppingCartByUserSaga),
    takeLatest(REMOVE_ITEM_FROM_SHOPPINGCART_REQUEST, removeItemFromShoppingCartSaga),
    takeLatest(REMOVE_PRODUCT_FROM_SHOPPINGCART_REQUEST, removeProductFromShoppingCartSaga)
  ])
}
