import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Public from 'components/Layout/Layout'
import SearchBar from 'components/SearchBar/SearchBar'
import BannerShopProducts from 'components/Banners/BannerShopProducts'
import { addProductToWishList } from 'redux/global/wishList/wishList.redux'
import ProductDetails from './ProductDetails'
import { addProductToShoppingCart } from 'redux/global/shoppingCart/shoppingCart.redux'
import { ProductInformation } from './ProductInformation'
import { productActions } from 'modules/Product/product.redux'
import Section from 'components/Section/Section'
import CategoriesMenu from 'components/Categories/CategoriesMenu'

function Product({ product, reviews, shoppingCartId, addProductToWishList, ...props }) {
	useEffect(() => {
		const { id } = props.match.params
		props.loadViewProduct(id)
	}, [])

	return product ? (
		<Public fluid admin={false}>
			<SearchBar>
				<CategoriesMenu />
			</SearchBar>
			<BannerShopProducts text={product.name} category={product.category.title} />
			<Section productName={product.name} contain="product-details spad">
				<ProductDetails product={product} />
				<ProductInformation product={product} reviews={reviews} />
			</Section>
		</Public>
	) : null
}

const mapStateToProps = ({ product, shoppingCart, reviews }) => ({
	product: product.product,
	shoppingCart,
	reviews: reviews.list
})
const mapDispatchToProps = {
	addProductToShoppingCart: addProductToShoppingCart,
	addProductToWishList: addProductToWishList,
	loadViewProduct: productActions.loadViewProductRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(Product)
