import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { addVisitToProduct } from 'modules/Visits/visits.redux'

function SearchView({ product, addVisitToProduct }) {
	const result = product.length
	return result > 0 ? (
		<section className="shoping-cart spad">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="shoping-cart-table">
							<table>
								<thead>
									<tr>
										<th style={{ paddingLeft: '3%' }}>Producto</th>
										<th style={{ paddingLeft: '10%' }}>Descripción</th>
										<th>Disponibilidad</th>
										<th style={{ paddingLeft: '1%' }}>Puntos</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{product.map((p) => (
										<tr key={p.id}>
											<td className="shoping-cart-item">
												<Link
													to={`/products/${p.id}`}
													onClick={() => addVisitToProduct(product)}
												>
													<div className="product-img">
														<img src={p.thumbnail.path} alt={p.name} />
													</div>
												</Link>
											</td>
											<td className="description-item">
												<h5>{p.name}</h5>
												<p>Categoría: {p.category ? p.category.title : ''}</p>
											</td>
											<td className="wish-list-stock">
												<h5 style={{ paddingLeft: '5%' }}>{p.stock} Piezas </h5>
											</td>
											<td className="shoping-cart-price">{p.price}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</section>
	) : (
		<div className="alert-empty">
			<div className="alert alert-info" role="alert">
				<h4 className="alert-heading">No se encontraron resultados</h4>
				<p className="mb-0">
					Te invitamos a ver nuestros{' '}
					<a style={{ color: '#0c5460', fontWeight: 'bold', textDecoration: 'underline' }} href="/shop">
						{' '}
						productos{' '}
					</a>
				</p>
			</div>
		</div>
	)
}

const mapStateToProps = ({ product }) => ({ product: product.searchProducts })
const mapDispatchToProps = { addVisitToProduct: addVisitToProduct }
export default connect(mapStateToProps, mapDispatchToProps)(SearchView)
