import React from 'react'

function BannerShopProducts({ text, category }) {
	return (
		<section className={'breadcrumb-section'}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 text-center">
						<div className="breadcrumb-text">
							<h2>{text}</h2>
							{category ? (<div className="breadcrumb-option">
								<span>{category}</span>
							</div>): null}
							
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default BannerShopProducts
