import { all, takeLatest, put, call } from 'redux-saga/effects'
import axios from 'axios'
import { alertActions } from 'modules/Alert/alert.redux'
import {
	ADD_VISIT_TO_PRODUCT_REQUEST,
	ADD_VISIT_TO_PRODUCT_SUCCESS,
	GET_VISITS_FROM_PRODUCT_REQUEST,
	GET_VISITS_FROM_PRODUCT_SUCCESS
} from './visits.redux'

export function* addVisitSaga(action) {
	try {
		// 1. guardar registro en la base de datos
		const { product } = action
		const visitsPost = { product: product.id }

		const { data: visitsRow } = yield call(axios.post, '/api/visits', visitsPost)

		// 2. agregar al state
		yield put({ type: ADD_VISIT_TO_PRODUCT_SUCCESS, visitsId: visitsRow.id, product })

		//3. Mandar mensaje al usuario
		yield put(alertActions.alertMessageSuccess('Increment visit'))
	} catch (error) {
		console.error(error)
	}
}

export function* getVisitsSaga(action) {
	try {
		const productId = action.product
		const url = `/api/visits/${productId}/product`
		const { data: visits } = yield call(axios.get, url)
		yield put({ type: GET_VISITS_FROM_PRODUCT_SUCCESS, visits: visits.visits })
	} catch (error) {
		console.log(error)
	}
}

export function* visitSagas() {
	yield all([
		takeLatest(ADD_VISIT_TO_PRODUCT_REQUEST, addVisitSaga),
		takeLatest(GET_VISITS_FROM_PRODUCT_REQUEST, getVisitsSaga)
	])
}
