import axios from 'axios'
import swal from 'sweetalert'
import jwt_decode from 'jwt-decode'
import { push } from 'connected-react-router'
import { call, put, select, all, takeLatest } from 'redux-saga/effects'
import { isExpire, setAuthToken } from './../../util/auth'
import { actions, types } from './auth.redux'
import { baseURL } from './../../util/helpers'
import { isEmpty } from './../../util/crud'
import { alertActions } from 'modules/Alert/alert.redux'

const concatURL = baseURL('/api/auth/')

/**
 * Login the user to the system.
 * @param   {object}   action.payload Data passed when the action was dispatched
 * @returns {iterator}
 */
export function* loginSaga({ payload }) {
  try {
    // 1. Consultar microservicio SharePoint
    // const url = `${process.env.REACT_APP_AUTH_URL}/api/auth`
    // const { data } = yield call(axios.post, url, payload)
    // const { token } = data.data
    //  Set token in axios Header
    if (payload.token) {
      setAuthToken(payload.token) // Set token to Auth header
      // Set token on localStorage
      yield localStorage.setItem('auth_token', payload.token)
      //  Decode token to get user data
      const decoded = jwt_decode(payload.token)
      if (decoded && isExpire(decoded.exp)) {
        return yield put(actions.logoutRequest())
      } else {
        // Consulta de endpoint de permisos
        const { data: dataPermissions } = yield call(axios.post, '/api/auth/getPermissions')
        const user = { ...decoded, ...dataPermissions, softtekId: decoded.id }

        // Verificar el estatus del usuarios en activeDirectory y db
        if (user.active === true) {
          // Guardar en reducer
          yield put(actions.loginSuccess(user))
        } else {
          yield put(alertActions.alertMessageWarning('User Inactive'))
        }
      }
    }
  } catch (error) {
    yield put(actions.loginFailure(error))
  }
}

export function* refreshProfile() {
  try {
    const userId = yield select(state => state.auth.user.id)
    const { data } = yield call(axios.get, `/api/users/${userId}`)
    yield put(actions.refreshProfileSuccess(data))
  } catch (error) {
    yield put(actions.refreshProfileFailure(error))
  }
}

export function* updateProfile({ payload }) {
  try {
    const { values } = payload
    const userId = yield select(state => state.auth.user.id)
    const url = `/api/users/${userId}`

    const historical = { cause: 'Actualización', description: 'Actualización del perfil' }
    const data = { payload: values, historical }
    const { data: updatedUser } = yield call(axios.put, url, data)

    yield put(actions.updateProfileSuccess(updatedUser))
    yield put(alertActions.alertMessageSuccess('Perfil actualizado'))
    yield put(push('/'))
  } catch (error) {
    yield put(actions.updateProfileFailure(error))
  }
}

/**
 * Logout the user on the system
 * 1 - Remove the token from localstorage
 * 2 - Remove the auth headers
 * 3 - Clean up the store->user redux node
 * @returns {iterator}
 */
export function* logoutSaga() {
  try {
    yield jwt_decode(localStorage.getItem('auth_token'))

    const getUserId = state => state.auth.user.id
    const user = yield select(u => getUserId(u))

    const url = concatURL('logout', user)
    yield call(axios.put, url, { login: false })
    // Remove token from localStorage
    localStorage.removeItem('auth_token')
    // Remove the auth header for future requests
    setAuthToken(false)
    // Unset the user on the redux store
    yield put(actions.logoutSuccess())
    yield put(alertActions.alertMessageSuccess('Sesión Finalizada'))
    yield put(push(window.location.reload(false)))
  } catch (error) {
    yield put(actions.logoutFailure())
  }
}

export function* checkLogin() {
  try {
    const token = localStorage.getItem('auth_token')
    if (token) {
      setAuthToken(token) // Set token to Auth header
      const decoded = jwt_decode(token) // Decode token to get user data
      if (decoded && isExpire(decoded.exp)) {
        return yield put(actions.logoutRequest())
      } else {
        const { data: dataPermissions } = yield call(axios.post, '/api/auth/getPermissions')
        const user = { ...decoded, ...dataPermissions, softtekId: decoded.id }

        if (user.active === true) return yield put(actions.loginSuccess(user))
      }
    }
    yield call(checkLoginCookies)
  } catch (error) {
    yield put(actions.loginFailure(error))
  }
}

function* checkLoginCookies() {
  const { data } = yield call(axios.get, '/api/oauth/login/check', {
    credentials: 'include',
    headers: { 'Access-Control-Allow-Credentials': true }
  })

  // Set token on local storage and in axios header
  if (!isEmpty(data.token)) {
    localStorage.setItem('auth_token', data.token)
    setAuthToken(data.token)
    return yield put(actions.loginSuccess(data.user))
  }
}

export function* authSagas() {
  yield all([
    takeLatest(types.LOGIN_REQUEST, loginSaga),
    takeLatest(types.LOGOUT_REQUEST, logoutSaga),
    takeLatest(types.CHECK_LOGIN_REQUEST, checkLogin),
    takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfile)
  ])
}
