import { createActions, createReducer } from 'reduxsauce'
import * as handlers from '../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    loadAllProductRequest: ['payload'],
    loadAllProductSuccess: ['payload'],
    loadAllProductFailure: ['payload'],

    loadProductHomeRequest: ['payload'],
    loadProductHomeSuccess: ['payload'],
    loadProductHomeFailure: ['payload'],

    loadAllHomeProductRequest: ['payload'],
    loadAllHomeProductSuccess: ['payload'],
    loadAllHomeProductFailure: ['payload'],

    loadProductRequest: ['payload'],
    loadProductSuccess: ['payload'],
    loadProductFailure: ['payload'],

    createProductRequest: ['payload'],
    createProductSuccess: ['payload'],
    createProductFailure: ['payload'],

    editProductRequest: ['payload'],
    editProductSuccess: ['payload'],
    editProductFailure: ['payload'],

    updateProductRequest: ['payload'],
    updateProductSuccess: ['payload'],
    updateProductFailure: ['payload'],

    toggleProductRequest: ['payload'],
    toggleProductSuccess: ['payload'],
    toggleProductFailure: ['payload'],

    deleteProductRequest: ['payload'],
    deleteProductSuccess: ['payload'],
    deleteProductFailure: ['payload'],

    loadAllPublicProductRequest: ['payload'],
    loadAllPublicProductSuccess: ['payload'],
    loadAllPublicProductFailure: ['payload'],

    loadPublicProductRequest: ['productId'],
    loadPublicProductSuccess: ['product'],
    loadPublicProductFailure: ['productId'],

    loadProductOptionsRequest: ['payload'],
    loadProductOptionsSuccess: ['payload'],
    loadProductOptionsFailure: ['payload'],

    loadLatestProductsRequest: ['payload'],
    loadLatestProductsSuccess: ['payload'],
    loadLatestProductsFailure: ['payload'],

    loadVisitsProductsRequest: ['payload'],
    loadVisitsProductsSuccess: ['payload'],
    loadVisitsProductsFailure: ['payload'],

    // Redux change
    loadReviewsProductsRequest: ['payload'],
    loadReviewsProductsSuccess: ['payload'],
    loadReviewsProductsFailure: ['payload'],

    loadViewProductRequest: ['productId'],
    loadViewProductSuccess: ['product'],
    loadViewProductFailure: ['productId'],

    searchProductRequest: ['payload'],
    searchProductSuccess: ['payload'],
    searchProductFailure: ['payload'],

    searchProductByCategoryRequest: ['payload'],
    searchProductByCategorySuccess: ['payload'],
    searchProductByCategoryFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {},
  options: {
    categories: []
  },
  publicList: [],
  homeList: [],
  homeListAll: [],
  latestProducts: [],
  visitsProducts: [],
  reviewProducts: [],
  searchProducts: [],
  searchProductsByCategory: [],
  error: {},
  loading: false
}

export function loadProductOptions(state, action) {
  return { ...state, options: { ...action.payload } }
}

export function publicLoadProduct(state, action) {
  const product = { ...state.product, ...action.product }
  return { ...state, product }
}

export function loadProductDetail(state, action) {
  return { ...state, productDetail: { ...action.payload } }
}

export const HANDLERS = {
  [Types.LOAD_ALL_PRODUCT_SUCCESS]: handlers.loadAllSuccess,
  [Types.LOAD_PRODUCT_SUCCESS]: handlers.loadSuccess,
  [Types.CREATE_PRODUCT_SUCCESS]: handlers.createSuccess,
  [Types.EDIT_PRODUCT_SUCCESS]: handlers.editSuccess,
  [Types.UPDATE_PRODUCT_SUCCESS]: handlers.updateSuccess,
  [Types.TOGGLE_PRODUCT_SUCCESS]: handlers.toggleSuccess,
  [Types.DELETE_PRODUCT_SUCCESS]: handlers.deleteSuccess,
  [Types.LOAD_PRODUCT_OPTIONS_SUCCESS]: loadProductOptions,
  [Types.LOAD_PUBLIC_PRODUCT_SUCCESS]: publicLoadProduct,
  [Types.LOAD_VIEW_PRODUCT_SUCCESS]: loadProductDetail,
  [Types.LOAD_PRODUCT_HOME_SUCCESS]: (state, action) => ({
    ...state,
    homeListAll: [...action.payload],
    loading: false,
    error: {}
  }),
  [Types.LOAD_ALL_HOME_PRODUCT_SUCCESS]: (state, action) => ({
    ...state,
    homeList: [...action.payload],
    loading: false,
    error: {}
  }),
  [Types.LOAD_LATEST_PRODUCTS_SUCCESS]: (state, action) => ({
    ...state,
    latestProducts: [...action.payload]
  }),
  [Types.LOAD_VISITS_PRODUCTS_SUCCESS]: (state, action) => ({
    ...state,
    visitsProducts: [...action.payload]
  }),
  // redux change
  [Types.LOAD_REVIEWS_PRODUCTS_SUCCESS]: (state, action) => ({
    ...state,
    reviewProducts: [...action.payload]
  }),
  // redux change
  [Types.LOAD_ALL_PUBLIC_PRODUCT_SUCCESS]: (state, action) => ({
    ...state,
    publicList: [...action.payload]
  }),
  [Types.SEARCH_PRODUCT_SUCCESS]: (state, action) => ({
    ...state,
    searchProducts: [...action.payload]
  }),
  [Types.SEARCH_PRODUCT_BY_CATEGORY_SUCCESS]: (state, action) => ({
    ...state,
    searchProductsByCategory: [...action.payload]
  })
}

export const productTypes = Types
export const productActions = Creators
export const product = createReducer(initialState, HANDLERS)
