import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { productActions } from 'modules/Product/product.redux'
import { categoryActions } from 'modules/Category/category.redux'
import ProductCard from './ProductCard'

export const ProductsGallery = ({
  products,
  loadProducts,
  productSearch,
  categories,
  loadCategories,
  searchProductsByCategory
}) => {
  useEffect(() => {
    loadProducts()
    loadCategories()
  }, [])

  const handleClick = e => {
    const title = e.currentTarget.getAttribute('value')
    searchProductsByCategory({ title })
  }

  const onClick = () => {
    searchProductsByCategory([])
  }

  const totalAllProducts = products.length
  const totalProducts = productSearch.length

  return (
    <section className='product'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-9 mb-3'>
                <ul style={{ display: 'flex', padding: 0 }}>
                  {categories.map(c => (
                    <button
                      data-filter='.oranges'
                      className='btnFeatured active'
                      key={c._id}
                      value={c.title}
                      onClick={handleClick}>
                      {c.title}
                    </button>
                  ))}
                </ul>
              </div>
              <div className='col-lg-3 mb-3'>
                <ul>
                  <button
                    value='All'
                    className='btnFeatured'
                    onClick={onClick}
                    data-filter='*'>
                    Todos los Productos
                  </button>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-md-9'>
            <div className='product-discount'>
              {productSearch.length === 0 ? (
                <div className='row'>
                  {products.map(p => (
                    <ProductCard size='col-lg-4 col-md-4 col-sm-6' key={p.id} product={p} />
                  ))}
                </div>
              ) : (
                <div className='row'>
                  {productSearch.map(p => (
                    <ProductCard size='col-lg-4 col-md-4 col-sm-6' key={p.id} product={p} />
                  ))}
                </div>
              )}
              <div className='filter-item'>
                <div className='row'>
                  <div className='col-lg-9 col-md-7 mx-auto'>
                    <div className='filter-found'>
                      {productSearch.length === 0 ? (
                        <h6>
                          <span>{totalAllProducts}</span> Productos encontrados
                        </h6>
                      ) : (
                        <h6>
                          <span>{totalProducts}</span> Productos encontrados
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='product-pagination'>
                <a href='./'>1</a>
                <a href='./'>2</a>
                <a href='./'>3</a>
                <a href='./'>
                  <i className='icon-long-arrow-right' />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ category, product }) => ({
  categories: category.publicList,
  products: product.publicList,
  productSearch: product.searchProductsByCategory
})
const mapDispatchToProps = {
  loadCategories: categoryActions.loadAllPublicCategoryRequest,
  loadProducts: productActions.loadAllPublicProductRequest,
  searchProductsByCategory: productActions.searchProductByCategoryRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductsGallery)
