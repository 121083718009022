import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import BannerInfo from 'components/Banners/BannerInfo'
import ProductsGalleryHome from 'components/Products/ProductsGalleryHome'
import VisitProducts from 'components/Products/VisitProducts'
import ReviewProducts from 'components/Products/ReviewProducts'
import LatestProducts from 'components/Products/LatestProducts'
import Hero from 'components/Hero/Hero'
import Carousel from 'components/Categories/Carousel'
import Section from 'components/Section/Section'
import SearchBar from 'components/SearchBar/SearchBar'

export const Home = () => {
	return (
		<Layout fluid admin={false}>
			<SearchBar />
			<Hero />
			<Carousel />
			<ProductsGalleryHome />
			<BannerInfo />
			<Section row contain="latest-product spad">
				<LatestProducts />
				<VisitProducts />
				<ReviewProducts />
			</Section>
		</Layout>
	)
}

const mapStateToProps = () => ({})
export default connect(mapStateToProps)(Home)
