import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { productActions } from 'modules/Product/product.redux'
import ProductCard from './ProductCard'

// Se muestra en Pagina Home con diferente grid
// Crear Query Para solo mostrar 6 Productos Random
export const ProductsGalleryHome = ({
  products,
  productsHome,
  loadProducts,
  loadHomeProducts,
  categories
}) => {
  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    loadHomeProducts()
    loadProducts()
  }, [])

  const handleClick = value => () => {
    const productFilter = productsHome.filter(e => e.category._id === value)
    setFiltered(productFilter)
  }

  const onClick = () => {
    setFiltered([])
  }

  return (
    <section className='featured spad'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='section-title'>
              <h2>Productos Destacados</h2>
            </div>
            <div className='row'>
              <div className='col-lg-9'>
                <ul style={{ display: 'flex', padding: 0 }}>
                  {categories.map(c => (
                    <button
                      data-filter='.oranges'
                      className='btnFeatured active'
                      key={c._id}
                      onClick={handleClick(c._id)}>
                      {c.title}
                    </button>
                  ))}
                </ul>
              </div>
              <div className='col-lg-3'>
                <ul>
                  <button
                    value='All'
                    className='btnFeatured active'
                    onClick={onClick}
                    data-filter='*'>
                    Todas las Categorias
                  </button>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {filtered.length === 0 ? (
          <div className='row'>
            {products.map(p => (
              <ProductCard size='col-lg-4 col-md-4 col-sm-6' key={p.id} product={p} />
            ))}
          </div>
        ) : (
          <div className='row'>
            {filtered.map(p => (
              <ProductCard size='col-lg-4 col-md-4 col-sm-6' key={p.id} product={p} />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

const mapStateToProps = ({ product, category }) => ({
  products: product.homeListAll,
  productsHome: product.homeList,
  categories: category.publicList
})
const mapDispatchToProps = {
  loadProducts: productActions.loadProductHomeRequest,
  loadHomeProducts: productActions.loadAllHomeProductRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductsGalleryHome)
