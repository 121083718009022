import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Form } from 'redux-form'
import { actions } from './auth.redux'
import AzureSignInButton from 'components/Auth/AzureSignInButton'

function LoginForm({ handleSubmit, login }) {
	return (
		<Form >
			<AzureSignInButton/>
		</Form>
	)
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = { login: actions.loginRequest }
const withConnect = connect(mapStateToProps, mapDispatchToProps)
const RFLoginForm = reduxForm({ form: 'login' })(LoginForm)

export default withConnect(RFLoginForm)
