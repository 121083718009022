import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take, select } from 'redux-saga/effects'
import { alertActions, alertTypes } from 'modules/Alert/alert.redux'
import { orderActions, orderTypes } from './order.redux'
import { isEmpty } from 'util/crud'
import { getTotal } from 'util/helpers'
import { reset } from 'redux/global/shoppingCart/shoppingCart.redux'
import * as handlers from 'redux/helpers/handlers'
import { pointActions } from 'modules/Points/point.redux'

/**
 * Display the orders list
 * @returns {iterator}
 */
export function* loadAllOrder() {
  try {
    const url = '/api/orders/'
    const { data: orders } = yield call(axios.get, url)
    yield put(orderActions.loadAllOrderSuccess(orders))
  } catch (error) {
    yield put(orderActions.loadAllOrderFailure(error))
  }
}

/**
 * Display the products in front
 * @returns {iterator}
 */
export function* loadAllPublicOrder() {
  try {
    const url = '/api/public/orders/'
    const { data: orders } = yield call(axios.get, url)
    yield put(orderActions.loadAllPublicOrderSuccess(orders))
  } catch (error) {
    yield put(orderActions.loadAllPublicOrderFailure(error))
  }
}

/**
 * Display a single orders record
 * @param   {object}   action.payload Data to take the id of the requested orders
 * @returns {iterator}
 */
export function* loadOrder({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/orders/${id}`
    const { data: orders } = yield call(axios.get, url)
    yield put(orderActions.loadOrderSuccess(orders))
  } catch (error) {
    yield put(orderActions.loadOrderFailure(error))
  }
}

export function crerateOrderPayload({ auth, shoppingCart }) {
  const email = auth.user.email
  const name = auth.user.name
  const user = auth.user.id
  const products = shoppingCart.products.map(p => {
    return {
      product: p.product.id,
      price: p.product.price,
      quantity: p.quantity
    }
  })
  return { user, products, email, name }
}

/**
 * Create an orders record
 * @param   {object}   action.payload Data to create an orders record
 * @returns {iterator}
 */
export function* createOrder({ payload }) {
  try {
    const state = yield select(state => state)
    const payload = crerateOrderPayload(state)

    const url = '/api/orders/'
    // Make the POST request
    const { data } = yield call(axios.post, url, payload)
    // Add new document to the list
    yield put(orderActions.createOrderSuccess(data))
    // Show notification
    yield put(alertActions.alertMessageSuccess('Tu compra fue realizada exitosamente'))
    // cargar shoppingCart
    yield put(reset())
    yield put(push('/'))
    // Caragar puntos de usuario
    yield put(pointActions.loadPointByUserRequest())
  } catch (error) {
    yield put(orderActions.createOrderFailure(error))
  }
}

/**
 * Load the information of a single orders record to edit it
 * @param   {object}   action.payload Data to take the id of the requested orders
 * @returns {iterator}
 */
export function* editOrder({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/orders/${id}`
    const { data: orders } = yield call(axios.get, url)
    const statusOptions = ['Pendiente', 'Listo', 'Entregado', 'Cancelado']

    const response = {
      ...orders,
      user: orders.user.fullName,
      userId: orders.user.id,
      total: getTotal(orders.products),
      status: { value: orders.status, label: statusOptions[orders.status] },
      products: orders.products.map(p => ({
        name: p.product.name,
        product: p.product._id,
        price: p.product.price,
        quantity: p.quantity
      }))
    }

    yield put(orderActions.editOrderSuccess(response))
  } catch (error) {
    yield put(orderActions.editOrderFailure(error))
  }
}

function formatOrder(payload) {
  return {
    ...payload,
    user: payload.user.id,
    status: payload.status.value
  }
}
/**
 * Update an orders record
 * @param   {object}   action.payload Data to update an orders record
 * @returns {iterator}
 */
export function* updateOrder({ payload }) {
  try {
    const getUserId = state => state.order.edit.userId

    yield put(alertActions.alertPromptTrackingShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_TRACKING_HIDE)
    const user = yield select(s => getUserId(s))
    const name = yield select(s => handlers.getUserName(s))
    const email = yield select(e => handlers.getUserEmail(e))

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/orders/${id}`
    const tracking = { cause: 'Actualización', comment: prompt.payload, createdBy: name }
    const points = { user: user }
    const load = formatOrder(values)
    const data = { payload: load, tracking, points }
    const { data: updatedOrder } = yield call(axios.put, url, data)
    yield put(orderActions.updateOrderSuccess(updatedOrder))
    // Success notification and return the user to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    // Cargar puntos de usuario
    yield put(pointActions.loadPointByUserRequest())
    yield put(push('/dashboard/order/list'))
  } catch (error) {
    yield put(orderActions.updateOrderFailure(error))
  }
}

/**
 * Validate an orders record
 * @param     values
 * @returns
 */
export async function validateOrder(values) {
  const url = '/api/orders/validate'
  const { data: result } = await axios.post(url, values)
  if (!isEmpty(result.errors)) throw result.errors
}

export function* getOrdersByUser(action) {
  try {
    const user = yield select(s => handlers.getUserId(s))
    const { data: orders } = yield call(axios.get, ` /api/orders/orderByUser/${user}`)
    yield put(orderActions.loadOrderByUserSuccess(orders))
  } catch (error) {
    yield put(orderActions.loadOrderByUserFailure(error))
  }
}

export function* orderSagas() {
  yield all([
    takeLatest(orderTypes.LOAD_ALL_ORDER_REQUEST, loadAllOrder),
    takeLatest(orderTypes.LOAD_ORDER_BY_USER_REQUEST, getOrdersByUser),
    takeLatest(orderTypes.LOAD_ORDER_REQUEST, loadOrder),
    takeLatest(orderTypes.CREATE_ORDER_REQUEST, createOrder),
    takeLatest(orderTypes.EDIT_ORDER_REQUEST, editOrder),
    takeLatest(orderTypes.UPDATE_ORDER_REQUEST, updateOrder)
  ])
}
