import React from 'react'
import Acl from 'components/Acl/Acl'
import { Link, useLocation } from 'react-router-dom'

function TableContainer({
  title,
  subtitle,
  children,
  view,
  toggle,
  inactive,
  showButtons = true,
  route,
  button,
  create
}) {
  const { pathname } = useLocation()
  const action = pathname.split('/')[3]

  return (
    <div className='col-12 mt-2 pt-2 mb-5'>
      {title && (
        <div className='header-h2'>
          <h2 className='mb-3 text-left'>{title}</h2>
        </div>
      )}
      <div className='card shadow mb-2'>
        <div className='card-header py-3 px-0'>
          <h5 className='flex-row align-items-center d-flex justify-content-between px-4'>
            {subtitle}
            {showButtons && (
              <div>
                {action === 'view' && (
                  <ButtonsView view={view} toggle={toggle} inactive={inactive} />
                )}
                {action === 'list' && <ButtonCreate route={route} create={create} />}
                {action === 'profile' && <ButtonsProfile view={view} />}
                {button}
              </div>
            )}
          </h5>
        </div>
        <div className='card-body table-responsive pb-2'>{children}</div>
      </div>
    </div>
  )
}

const ButtonsView = ({ toggle, view, track, routeTrack, inactive }) => {
  return (
    <Acl action='update'>
      {track && (
        <Link className='no-style mr-2' to={routeTrack}>
          <button className='btn btn-secondary'>
            <i className='icon-edit mr-2' style={{ verticalAlign: 'text-bottom' }} />
            Agregar Tracking
          </button>
        </Link>
      )}
      <Link className='no-style mr-2' to={`../edit/${view._id}`}>
        <button className='btn btn-info'>
          <i className='icon-edit mr-2' style={{ verticalAlign: 'text-bottom' }} />
          Editar
        </button>
      </Link>
      {!inactive && (
        <button
          className={`btn btn-${view.active ? 'danger' : 'success'} mr-2`}
          onClick={() => toggle(view)}>
          <i className='icon-power-off mr-2' style={{ verticalAlign: 'text-bottom' }} />
          {view.active ? 'Desactivar' : 'Activar'}
        </button>
      )}
    </Acl>
  )
}

export function ButtonCreate({ style, route, icon, label, create }) {
  return (
    <Acl action='create'>
      {!create && (
        <Link className='float-right' style={style} to={route}>
          <button className='btn btn-primary d-flex align-items-center'>
            <i className={`${icon} mr-2`} />
            <span>{label}</span>
          </button>
        </Link>
      )}
    </Acl>
  )
}

const ButtonsProfile = () => {
  return (
    <Acl action='update'>
      <Link className='no-style mr-2' to={`/dashboard/user/profile/edit`}>
        <button className='btn btn-info'>
          <i className='icon-edit mr-2' style={{ verticalAlign: 'text-bottom' }} />
          Editar
        </button>
      </Link>
    </Acl>
  )
}

ButtonCreate.defaultProps = {
  route: './create',
  icon: 'icon-plus-square',
  label: 'Agregar'
}

export default TableContainer
