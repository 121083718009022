import React, { Component } from 'react'
import { connect } from 'react-redux'
import MailerForm from './forms/MailerForm'
import Layout from 'components/Layout/Layout'
import { configurationActions } from './configuration.redux'

class ConfigurationCreate extends Component {
	submit = (values) => {
		this.props.createConfiguration(values)
	}

	render() {
		return (
			<Layout>
				<MailerForm {...this.props} label="Agregar" button="add" onSubmit={this.submit} />
			</Layout>
		)
	}
}

const mapStateToProps = ({ configuration }) => ({ configuration })
const mapDispatchToProps = {
	createConfiguration: configurationActions.createConfigurationRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationCreate)
