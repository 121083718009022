import { createActions, createReducer } from 'reduxsauce'
import { isEmpty } from './../../util/crud'

const INITIAL_STATE = {
	user: {
		permissions: []
	},
	isAuthenticated: false,
	loading: false
}

const { Creators, Types } = createActions(
	{
		loginRequest: [ 'payload' ],
		loginSuccess: [ 'payload' ],
		loginFailure: [ 'payload' ],

		checkLoginRequest: [ 'payload' ],
		checkLoginSuccess: [ 'payload' ],
		checkLoginFailure: [ 'payload' ],

		logoutRequest: [ 'payload' ],
		logoutSuccess: [ 'payload' ],
		logoutFailure: [ 'payload' ],

		resetPasswordRequest: [ 'payload' ],
		resetPasswordSuccess: [ 'payload' ],
		resetPasswordFailure: [ 'payload' ],

		requestRecoverPasswordRequest: [ 'payload' ],
		requestRecoverPasswordSuccess: [ 'payload' ],
		requestRecoverPasswordFailure: [ 'payload' ],

		recoverPasswordRequest: [ 'payload' ],
		recoverPasswordSuccess: [ 'payload' ],
		recoverPasswordFailure: [ 'payload' ],

		updateProfileRequest: [ 'payload' ],
		updateProfileSuccess: [ 'payload' ],
		updateProfileFailure: [ 'payload' ]
	},
	{}
)

export const HANDLERS = {
	[Types.LOGIN_SUCCESS]: (_state, action) => {
		const user = action.payload || {}
		const isAuthenticated = !isEmpty(user)
		return { user, isAuthenticated }
	},
	[Types.LOGOUT_SUCCESS]: () => INITIAL_STATE,
	[Types.REQUEST_RECOVER_PASSWORD_REQUEST]: (state) => ({ ...state, loading: true }),
	[Types.REQUEST_RECOVER_PASSWORD_SUCCESS]: (state) => ({ ...state, loading: false }),
	[Types.REQUEST_RECOVER_PASSWORD_FAILURE]: (state) => ({ ...state, loading: false }),
	[Types.UPDATE_PROFILE_SUCCESS]: function(state, action) {
		return { ...state, user: { ...state.user, ...action.payload } }
	}
}

export const actions = Creators
export const types = Types
export const auth = createReducer(INITIAL_STATE, HANDLERS)
