import React from 'react'
import ReviewsList from 'components/Products/Reviews/ReviewsList'

export const ProductInformation = ({ product, reviews }) => {

  const totalReviews = reviews.length

  return (
    <div>
      <div className='col-lg-12'>
        <div className='product-details-tab'>
          <ul className='nav nav-tabs' role='tablist'>          
            <li className='nav-item'>
              <a
                className='nav-link active design-review'
                data-toggle='tab'
                href='#tabs-1'
                role='tab'
                aria-selected='true'>
                Reseñas 
              </a>
            </li>
          </ul>
          <div className='tab-content'>            
            {totalReviews === 0 ? (
              <div className='tab-pane active' id='tabs-1' role='tabpanel'>
                <div className='product-details-tab-desc'> 
                  <h6 style={{textAlign: 'center'}} >Sin Reseñas</h6>  
                    {product.visibility?.reviews && <ReviewsList />}
                </div>
              </div> 
            ) : (
              <div className='tab-pane active' id='tabs-1' role='tabpanel'>
              {product.visibility?.reviews && <ReviewsList />}
            </div>
              
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default (ProductInformation)
