import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import { isEmpty } from './../../util/crud'
import { userActions } from './user.redux'
import UserForm from './UserForm'

class UserEdit extends Component {
	componentDidMount() {
		const { id } = this.props.match.params
		this.props.editUserRequest({ id })
	}

	componentWillUnmount() {
		this.props.editUserSuccess()
	}

	onCancel = () => {
		this.props.history.push('/dashboard/user/list')
	}

	submit = (values) => {
		const { id } = this.props.match.params
		this.props.updateUser({ id, values })
	}

	render() {
		const values = this.props.user.edit

		return (
			<Layout>
				{!isEmpty(values) && (
					<UserForm
						{...this.props}
						label="Actualizar"
						button="edit"
						onCancel={this.onCancel}
						onSubmit={this.submit}
						initialValues={values}
					/>
				)}
			</Layout>
		)
	}
}

const mapStateToProps = ({ user }) => ({ user })
const mapDispatchToProps = {
	updateUser: userActions.updateUserRequest,
	editUserRequest: userActions.editUserRequest,
	editUserSuccess: userActions.editUserSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit)
