/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Public from 'components/Layout/Layout'
import SearchBar from 'components/SearchBar/SearchBar'
import BannerShopProducts from 'components/Banners/BannerShopProducts'
import CategoriesMenu from 'components/Categories/CategoriesMenu'
import SearchView from 'components/Products/Search/SearchView'

function SearchProducts() {
  return (
    <Public fluid admin={false}>
      <SearchBar>
        <CategoriesMenu />
      </SearchBar>
      <BannerShopProducts text='Productos' />
      <SearchView />
    </Public>
  )
}

export default SearchProducts
