const sliderConfig = (items) => ({
	infinite: items > 4,
	// dots: true,
	speed: 600,
	slidesToShow: 4,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
})

export default sliderConfig
