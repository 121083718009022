import Home from './pages/Home/Home'
import Shop from './pages/Shop/Shop'
import ShopCart from 'pages/ShopCart/ShopCart'
import WishListPage from 'pages/WishList/WishListPage'
import NoticeOfPrivacy from './pages/Legal/NoticeOfPrivacy'
import SearchProducts from 'pages/Search/SearchProducts'
import OrderByUser from 'modules/Order/OrderByUser'
import TrackingByOrder from 'modules/Tracking/TrackingByOrder'

// Modules
import categoryRoutes from 'modules/Category/category.routes'
import productRoutes from 'modules/Product/product.routes'
import userRoutes from 'modules/Users/user.routes'
import authRoutes from 'modules/Auth/auth.routes'
import loadRoutes from 'modules/Loads/load.routes'
import pointRoutes from 'modules/Points/point.routes'
import Menu from './components/Menu/Menu'
import configurationRoutes from 'modules/Configuration/configuration.routes'
import InitialConfiguration from 'modules/InitialConfiguration/InitialConfiguration'
import orderRoutes from 'modules/Order/order.routes'
import userPointsRoutes from 'modules/UserPoints/userPoints.routes'

export const routes = [
	{ path: '/', public: true, component: Home },
	{ path: '/configure', public: true, component: InitialConfiguration },
	{ path: '/shop', public: true, component: Shop },
	{ path: '/shopCart', public: true, component: ShopCart },
	{ path: '/wishList', public: true, component: WishListPage },
	{ path: '/searchProduct', public: true, component: SearchProducts },
	{ path: '/notice-of-privacy', public: true, component: NoticeOfPrivacy },
	{ path: '/order/:id', public:true, component: OrderByUser },
	{ path: '/order/trackings/:id', public:true, component: TrackingByOrder },
	{ path: '/dashboard/menu', bypass: true, component: Menu },
	...authRoutes,
	...categoryRoutes,
	...productRoutes,
	...userRoutes,
	...loadRoutes,
	...pointRoutes,
	...configurationRoutes,
	...orderRoutes,
	...userPointsRoutes
]
