import UserCreate from './UserCreate'
import UserList from './UserList'
import UserEdit from './UserEdit'
import UserView from './UserView'
import UserProfileView from './UserProfileView'
import ProfileEdit from './ProfileEdit'

const routes = [
  { path: '/dashboard/user/list', component: UserList },
  { path: '/dashboard/user/create', component: UserCreate },
  { path: '/dashboard/user/edit/:id', component: UserEdit },
  { path: '/dashboard/user/profile/edit', component: ProfileEdit, bypass: true },
  { path: '/dashboard/user/view/:id', component: UserView },
  { path: '/dashboard/user/profile/:id', component: UserProfileView, bypass: true }
]

export default routes
