import React from 'react'
import thumbnail from 'assets/images/user-thumbnail.png'
import moment from 'moment'
import { connect } from 'react-redux'
import Acl from 'components/Acl/Acl'
import Img from 'react-cool-img'
import { reviewsActions } from 'modules/Reviews/reviews.redux'

function Comment({ _id, picture, author, createdAt, review, deletComment, user }) {
	return (
		<li className="comment">
			<div className="vcard bio">
				<Img src={picture} alt={author} />
			</div>
			<div className="comment-body">
				<Acl module="campaign" action="update">
					<i
						className="icon-times"
						style={{ float: 'right', color: '#ef6462', cursor: 'pointer' }}
						onClick={() => deletComment(_id)}
						onKeyPress={() => {}}
						role="presentation"
					/>
				</Acl>
				<p style={{ wordBreak: 'break-word', color: 'black', fontWeight: 'bold', margin: 0 }}>"{review}"</p>
				<div className="meta">
					{author} / {moment(createdAt).fromNow()}
				</div>
			</div>
		</li>
	)
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

const mapDispatchToProps = {
	deletComment: reviewsActions.deleteProductReviewRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment)

Comment.defaultProps = {
	picture: thumbnail
}
