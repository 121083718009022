import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import { isEmpty } from './../../util/crud'
import { actions as authActions } from './../Auth/auth.redux'
import { userActions } from './user.redux'
import ProfileForm from './ProfileForm'

class ProfileEdit extends Component {
	componentDidMount() {
		const id = this.props.auth.user.id
		this.props.editUserRequest({ id })
	}

	componentWillUnmount() {
		this.props.editUserSuccess()
	}

	onCancel = () => {
		this.props.history.push('/dashboard/user/list')
	}

	submit = (values) => {
		this.props.updateProfile({ values })
	}

	render() {
		const values = this.props.user.edit

		return (
			<Layout>
				{!isEmpty(values) && (
					<ProfileForm
						{...this.props}
						label="Actualizar"
						button="edit"
						onCancel={this.onCancel}
						onSubmit={this.submit}
						initialValues={values}
					/>
				)}
			</Layout>
		)
	}
}

const mapStateToProps = ({ user, auth }) => ({ user, auth })
const mapDispatchToProps = {
	updateProfile: authActions.updateProfileRequest,
	editUserRequest: userActions.editUserRequest,
	editUserSuccess: userActions.editUserSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit)
