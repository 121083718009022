import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import axios from 'axios'
import { alertActions } from '../../../modules/Alert/alert.redux'
import * as handlers from './../../../redux/helpers/handlers'
import {
	ADD_PRODUCT_TO_WISHLIST_REQUEST,
	ADD_PRODUCT_TO_WISHLIST_SUCCESS,
	REMOVE_PRODUCT_FROM_WISHLIST_REQUEST,
	REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS,
	GET_WISHLIST_REQUEST,
	GET_WISHLIST_REQUEST_SUCCESS
} from './wishList.redux'

export function* addProudctToWishListSaga(action) {
	try {
		//1. validar si el usuario esta logeado
		const user = yield select((s) => handlers.getUserId(s))
		if (!user) return yield put(alertActions.alertMessageWarning('Por favor iniciar sesión'))

		//2. si existe ya en la lista no agregarlo
		const { product } = action
		const isInWishList = yield select((state) => state.wishList.find((w) => w.product.id === product.id))

		if (isInWishList) return yield put(alertActions.alertMessageWarning('Producto ya existe en la lista de Deseos'))

		// 3. guardar registro en la base de datos
		const wishListPost = { user, product: product.id }
		const { data: wishListRow } = yield call(axios.post, '/api/public/wishList', wishListPost)

		// 4. agregar al state
		yield put({ type: ADD_PRODUCT_TO_WISHLIST_SUCCESS, whishListId: wishListRow.id, product })

		//5 Mandar mensaje al usuario
		yield put(alertActions.alertMessageWish('Producto agregado a la lista de deseos'))
	} catch (error) {
		yield put(alertActions.alertMessageError('Problema al agregar producto a la lista de deseos'))
	}
}

export function* getWishListByUserSaga(action) {
	try {
		//1. validar si el usuario esta logeado
		const user = yield select((s) => handlers.getUserId(s))
		if (!user) return yield put(alertActions.alertMessageWarning('Por favor iniciar sesión'))

		const { data: wishList } = yield call(axios.post, '/api/public/wishList/getWishListByUser', {
			user
		})
		yield all(
			wishList.map((row) =>
				put({ type: GET_WISHLIST_REQUEST_SUCCESS, whishListId: row.id, product: row.product })
			)
		)
	} catch (error) {
		console.log(error)
	}
}

export function* removeProductFromWishListSaga(action) {
	try {
		const wishListId = action.wishListId
		const url = `/api/public/wishList/${wishListId}`
		yield call(axios.delete, url)

		yield put({ type: REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS, wishListId })
		yield put(alertActions.alertMessageError('Se eliminó el producto de la lista de deseos'))
	} catch (error) {
		yield put(alertActions.alertMessageError('Problema al eliminar producto de la lista de deseos'))
	}
}

export function* wishSagas() {
	yield all([
		takeLatest(ADD_PRODUCT_TO_WISHLIST_REQUEST, addProudctToWishListSaga),
		takeLatest(GET_WISHLIST_REQUEST, getWishListByUserSaga),
		takeLatest(REMOVE_PRODUCT_FROM_WISHLIST_REQUEST, removeProductFromWishListSaga)
	])
}
