import React from 'react'
import SearchForm from './SearchForm'

function SearchBar() {
	return (
		<section className="hero hero-normal">
			<div className="container">
				<div className="row">
					<div className="dropdown col-lg-3 hero-categories-all" />
					<div className="col-lg-9" style={{ padding: 0 }}>
						<div className="hero-search">
							<div className="hero-search-form">
								<SearchForm />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SearchBar
