import React, { useEffect } from 'react'
import Layout from 'components/Layout/Layout'
import DataTable from 'components/Table/Table'
import { columns } from './point.helpers'
import TableContainer from 'components/Container/TableContainer'
import { connect } from 'react-redux'
import { useExportSelected } from 'hooks/useExportSelected'
import { pointActions } from 'modules/Points/point.redux'

function UserPointsList(props) {
  const [selectRow, handleExport] = useExportSelected(
    'Puntos de Usuarios',
    '/api/points/exportUserPoints'
  )

  useEffect(() => {
    props.loadAllPoints()
  }, [])

  return (
    <Layout>
      <TableContainer title='Puntos' subtitle='Lista' showButtons={false}>
        <DataTable
          {...props}
          data={props.points}
          structure={columns}
          onExport={handleExport}
          selectRow={selectRow}
          selected={selectRow.selected}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = ({ point }) => ({ points: point.listPoints })
const mapDispatchToProps = {
  loadAllPoints: pointActions.loadAllPointsRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(UserPointsList)
