import React from 'react'
import SearchBar from 'components/SearchBar/SearchBar'
import BannerShopProducts from '../../components/Banners/BannerShopProducts'
import ShoppingCart from '../../components/ShoppingCart/ShoppingCart'
import Public from 'components/Layout/Layout'
import CategoriesMenu from 'components/Categories/CategoriesMenu'

function ShopCart() {
	return (
		<Public fluid admin={false}>
			<SearchBar>
				<CategoriesMenu />
			</SearchBar>
			<BannerShopProducts text="Carrito de Compra" />
			<ShoppingCart />
		</Public>
	)
}

export default ShopCart
