import React from 'react'
import banner from 'assets/images/banner.png'
import banner2 from 'assets/images/banner2.png'

function BannerInfo() {
	return (
		<div className="banner">
			<div className="container p-0">
				<div className="row">
					<div className="col-lg-6 col-md-6 col-sm-6">
						<div className="banner-pic">
							<img src={banner} alt="" />
						</div>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-6">
						<div className="banner-pic">
						<a href="mailto:myrewards@softtek.com">
							<img src={banner2} alt="" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BannerInfo
