import React from 'react'

function BannerShop() {
	return (
		<div className="col-lg-12">
			<div className="hero-item set-bg" data-setbg="img/hero/home.jpg">
				<div className="hero-text">
					<h2>#BeWellness</h2>
					<p>Recompensa tus hábitos saludables.</p>
					<a href="/shop" className="primary-btn">
						Tienda
					</a>
				</div>
			</div>
		</div>
	)
}

export default BannerShop
