/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'
import { userActions } from './user.redux'
import { pointActions } from 'modules/Points/point.redux'
import { isEmpty } from 'util/crud'
import { Row, Col } from 'reactstrap'
import { PermissionsTable } from './UserView'
import thumbnail from 'assets/images/user-thumbnail.png'
import Img from 'react-cool-img'

function UserProfileView(props) {
	useEffect(() => {
		const { id } = props.match.params
		props.loadUser({ id })
		props.loadPoint()
	}, [])

	const points = props.points
	const point = points.map((p) => ({ pointUser: p.points, operation: p.operation }))
	const totalPoints = point.reduce((a, v) => (a = a + v.pointUser), 0)

	const { view } = props.user
	const roles = [ 'Administrador total', 'Administrador restringido', 'Usuario' ]

	return (
		<Layout>
			{!isEmpty(view) && (
				<TableContainer title="Usuario" subtitle="Información del registro" view={view}>
					<Row>
						<Col md="4">
							<Img
								src={view.thumbnail ? view.thumbnail.path : thumbnail}
								height="200"
								width="200"
								className="d-block mx-auto"
								border="5"
								style={{ padding: '0.4rem', objectFit: 'cover' }}
							/>
						</Col>
						<Col md="8">
							<ul>
								<ListItem label="Nombre" value={view.name} />
								<ListItem label="Apellidos" value={view.lastName} />
								<ListItem label="Correo electrónico" value={view.email} />
								<ListItem label="Rol" value={roles[view.role]} />
								<ListItem label="Puntos" value={totalPoints} />
							</ul>
						</Col>
					</Row>
				</TableContainer>
			)}

			{view.role === 1 && <PermissionsTable permissions={view.permissions} />}
		</Layout>
	)
}

const mapStateToProps = ({ user, point }) => ({ user, points: point.pointList })
const mapDispatchToProps = {
	loadUser: userActions.loadUserRequest,
	loadPoint: pointActions.loadPointByUserRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfileView)
