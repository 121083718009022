import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, Table } from 'reactstrap'
import { reduxForm, formValueSelector, change, FieldArray, Field } from 'redux-form'
import { RFInput, RFSelect, RFCheckbox } from 'components/Inputs/RFFields'
import FormContainer from 'components/Container/FormContainer'
import BasicCard from 'components/Cards/BasicCard'
import { withForm } from 'util/withForm'

import { userActions } from './user.redux'
import { isEmpty } from 'util/helpers'
import { required, substring, isEmail, isSofttekEmail } from 'util/form'
import { Input } from 'components/Inputs/RFInput'

const limit30 = substring(0, 30)

const RenderPermissions = ({ fields, options, change }) => {
  const actions = ['read', 'create', 'update']

  const isChecked = id => document.getElementById(id)?.checked

  const handleCheckboxClick = (action, p) => event => {
    if (action === 'create' || action === 'update') {
      const update = isChecked(`${p}.update`)
      const create = isChecked(`${p}.update`)
      if (update || create || event.target.checked) {
        change(`${p}.read`, true)
        document.getElementById(`${p}.read`).disabled = true
      } else {
        document.getElementById(`${p}.read`).disabled = false
      }
    }
  }
  return (
    <Table className='matrix-permissions'>
      <thead>
        <tr className='text-center'>
          <th>Módulo</th>
          <th>Consultar</th>
          <th>Crear</th>
          <th>Actualizar</th>
        </tr>
      </thead>
      <tbody>
        {fields.map(p => {
          return (
            <tr>
              <td className='text-center'>
                <Field
                  disabled
                  component={Input}
                  name={`${p}.module`}
                  type='select'
                  parentClassName='list-inline-item text-left'
                  validate={[required]}>
                  {!isEmpty(options) &&
                    options.map(({ _id, label }) => (
                      <option key={_id} value={_id}>
                        {label}
                      </option>
                    ))}
                </Field>
              </td>

              {actions.map(action =>
                `${p}.module` === 'permissions[1].module' ? (
                  <td>
                    <RFCheckbox
                      id={`${p}.${action}`}
                      name={`${p}.${action}`}
                      defaultValue={false}
                      onClick={handleCheckboxClick(action, p)}
                      disabled={action === 'create'}
                    />
                  </td>
                ) : `${p}.module` === 'permissions[3].module' ? (
                  <td>
                    <RFCheckbox
                      id={`${p}.${action}`}
                      name={`${p}.${action}`}
                      defaultValue={false}
                      onClick={handleCheckboxClick(action, p)}
                      disabled={action === 'update'}
                    />
                  </td>
                ) : (
                  <td className='text-center'>
                    <RFCheckbox
                      id={`${p}.${action}`}
                      name={`${p}.${action}`}
                      defaultValue={false}
                      onClick={handleCheckboxClick(action, p)}
                      disabled={
                        action === 'read' &&
                        (isChecked(`${p}.update`) || isChecked(`${p}.create`))
                      }
                    />
                  </td>
                )
              )}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

function UserForm({ handleSubmit, option, pristine, valid, submitting, ...props }) {
  const status = { pristine, valid, submitting }
  const roles = [
    { value: 0, label: 'Administrador total' },
    { value: 1, label: 'Administrador restringido' },
    { value: 2, label: 'Usuario' }
  ]

  return (
    <Fragment>
      <FormContainer {...props} title='Usuarios' onClick={handleSubmit} status={status}>
        <Form>
          <Row>
            <Col md='6'>
              <RFInput name='name' label='Nombre' validate={[required]} normalize={limit30} />
            </Col>
            <Col md='6'>
              <RFInput
                name='lastName'
                label='Apellidos'
                validate={[required]}
                normalize={limit30}
              />
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              {}
              <RFInput
                name='email'
                type='email'
                label='Correo electrónico'
                validate={[required, isEmail, isSofttekEmail]}
              />
            </Col>
            <Col md='6'>
              <RFSelect name='role' label='Rol' options={roles} validate={[required]} />
            </Col>
          </Row>
        </Form>
      </FormContainer>
      {/* eslint-disable-next-line eqeqeq */}
      {props.role == 1 && !isEmpty(props.modules) && (
        <div className='w-100' key={props.permissions.length}>
          <BasicCard header='Matriz de permisos' className='px-3'>
            <FieldArray
              name='permissions'
              component={RenderPermissions}
              options={props.modules}
              change={props.change}
            />
          </BasicCard>
        </div>
      )}
    </Fragment>
  )
}

const defaultPermissions = module => {
  return {
    module: module._id,
    read: false,
    create: false,
    update: false,
    delete: false,
    isNew: true
  }
}

const mergePermissions = (modules = [], permissions = []) => {
  return modules.map(item => {
    const permissionIdx = permissions.findIndex(p => p.module === item._id)
    return permissionIdx === -1 ? defaultPermissions(item) : permissions[permissionIdx]
  })
}

const mapStateToProps = (state, { initialValues }) => {
  const selector = formValueSelector('user')
  const values = !isEmpty(initialValues) ? initialValues : {}
  const { modules } = state.user.options
  const permissions = mergePermissions(modules, values.permissions)
  const role = selector(state, 'role')

  const result = {
    initialValues: { ...values, permissions },
    permissions: selector(state, 'permissions'),
    role: role ? role.value : null,
    user: state.auth.user,
    modules
  }

  return result
}

const withConnect = connect(mapStateToProps, { ...userActions, change })
export default withConnect(withForm(reduxForm({ form: 'user' })(UserForm)))
