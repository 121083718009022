import React from 'react'
import { connect } from 'react-redux'
import {
  addProductToShoppingCart,
  removeItemFromShoppingCart,
  removeProductFromShoppingCart,
  getShoppingCart
} from '../../redux/global/shoppingCart/shoppingCart.redux'

export const ShoppingCartItem = ({
  quantity,
  total,
  product,
  shoppingCartId,
  addProductToShoppingCart,
  removeItemFromShoppingCart,
  removeProductFromShoppingCart
}) => {
  return (
    <tr>
      <td className='shoping-cart-item'>
        <div className='product-img'>
          <img src={product.thumbnail.path} alt={product.name} />
        </div>
      </td>

      <td>
        <h5>{product.name}</h5>
        <p>Categoría: {product.category ? product.category.title : ''}</p>
      </td>

      <td className='shoping-cart-price'>{product.price}</td>

      <td className='shoping-cart-quantity'>
        <div className='quantity'>
          <div className='pro-qty'>
            <button
              onClick={() => removeItemFromShoppingCart(shoppingCartId)}
              className='icon-minus plus'
            />

            <span>{quantity}</span>

            <button
              onClick={() => addProductToShoppingCart(product)}
              className='icon-plus plus'
            />
          </div>
        </div>
      </td>

      <td className='shoping-cart-price'>{total}</td>

      <td className='shoping-cart-item-close'>
        <button
          onClick={() => removeProductFromShoppingCart(shoppingCartId, product.id)}
          className='icon-remove'
        />
      </td>
    </tr>
  )
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {
  addProductToShoppingCart: addProductToShoppingCart,
  removeItemFromShoppingCart: removeItemFromShoppingCart,
  removeProductFromShoppingCart: removeProductFromShoppingCart,
  getShoppingCart: getShoppingCart
}
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartItem)
