import React from 'react'
import { Button } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from './../../authConfig'
import { connect } from 'react-redux'
import { actions } from './../../modules/Auth/auth.redux'
import axios from 'axios'

function AzureSignInButton({ loginUser }) {
  // obtenemos nuestra instancia

  const { instance } = useMsal()

  function handleLogin(instance) {
    instance
      .loginPopup(loginRequest)
      .then(async result => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${result.accessToken}`
        const data = {
          email: `${result.account.username}`,
          username: result.account.name,
          token: result.accessToken
        }
        loginUser(data)
      })
      .catch(e => {
        console.error(e)
      })
  }

  return (
    <>
      <div className=' col-12'>
        <Button className='primary-btn log-btn' style={{width:'100%', borderRadius:'0px'}} onClick={() => handleLogin(instance)}>
          Iniciar Sesión
        </Button>
      </div>
    </>
  )
}
const mapStateToProps = ({ product }) => ({ products: product.reviewProducts })
const mapDispatchToProps = {
  loginUser: actions.loginRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(AzureSignInButton)
