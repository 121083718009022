import swal from 'sweetalert2'

export const toastConfig = {
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 2000,
	timerProgressBar: true,
	onOpen: (toast) => {
		toast.addEventListener('mouseenter', swal.stopTimer)
		toast.addEventListener('mouseleave', swal.resumeTimer)
	}
}

export const isEmpty = () => {
	return swal.fire({
		title: 'Sin registros',
		text: 'No hay registros existentes',
		icon: 'warning',
		showCloseButton: false,
		showCancelButton: true,
		cancelButtonText: 'Regresar',
		confirmButtonText: 'Agregar registros',
		allowEscapeKey: false,
		allowOutsideClick: false
	})
}

export const isEmptyGoBack = () => {
	return swal.fire({
		title: 'Sin registros',
		text: 'No hay registros existentes',
		icon: 'warning',
		showCloseButton: false,
		showCancelButton: false,
		confirmButtonText: 'Regresar',
		allowEscapeKey: false,
		allowOutsideClick: false
	})
}

export const addProductConfig = (title) => ({
	icon: 'success',
	background: '#f3fbf4',
	iconHtml: '<i class="icon-shopping-cart" style="font-size: 20px"></i>',
	title
})
export const addWishConfig = (title) => ({
	icon: 'info',
	background: '#eef5ff',
	iconHtml: '<i class="icon-heart" style="font-size:20px"></i>',
	title
})

export const warningCustomConfig = (title) => ({
	icon: 'error',
	background: '#feeff0',
	iconHtml: '<i class="icon-exclamation" style="font-size:20px"></i>',
	title
})
export const successConfig = (title) => ({
	icon: 'success',
	background: '#f3fbf4',
	title
})
export const warningConfig = (title) => ({ icon: 'warning', background: '#FBEEE6', title })
export const errorConfig = (title) => ({ icon: 'error', background: '#feeff0', title })

export const promptConfing = {
	input: 'textarea',
	title: 'Justificación',
	inputPlaceholder: 'Escriba una justificación del cambio',
	showCancelButton: true,
	allowOutsideClick: false,
	allowEscapeKey: false,
	allowEnterKey: false
}

export const promptTracking = {
	input: 'textarea',
	title: 'Actualización',
	inputPlaceholder: 'Cambios en la orden',
	showCancelButton: true,
	allowOutsideClick: false,
	allowEscapeKey: false,
	allowEnterKey: false
}

export const toast = swal.mixin(toastConfig)
export const prompt = () => swal.fire(promptConfing)
export const propmtTracking = () => swal.fire(promptTracking)
