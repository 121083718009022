import React, { Component } from 'react'
import { connect } from 'react-redux'
import { productActions } from './product.redux'
import { isEmpty } from './../../util/crud'
import ProductForm from './ProductForm'
import Layout from 'components/Layout/Layout'

class ProductEdit extends Component {
	componentDidMount() {
		const { id } = this.props.match.params
		this.props.editProductRequest({ id })
	}

	componentWillUnmount() {
		this.props.editProductSuccess()
	}

	onCancel = () => {
		this.props.history.push('/dashboard/product/list')
	}

	submit = (values) => {
		const { id } = this.props.match.params
		this.props.updateProduct({ id, values })
	}

	render() {
		const values = this.props.product.edit

		return (
			<Layout>
				{!isEmpty(values) && (
					<ProductForm
						{...this.props}
						label="Actualizar"
						button="edit"
						onCancel={this.onCancel}
						onSubmit={this.submit}
						initialValues={values}
					/>
				)}
			</Layout>
		)
	}
}

const mapStateToProps = ({ product }) => ({ product })
const mapDispatchToProps = {
	updateProduct: productActions.updateProductRequest,
	editProductRequest: productActions.editProductRequest,
	editProductSuccess: productActions.editProductSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductEdit)
