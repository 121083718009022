import React from 'react'
import { connect } from 'react-redux'
import { addProductToShoppingCart } from 'redux/global/shoppingCart/shoppingCart.redux'
import { addProductToWishList } from 'redux/global/wishList/wishList.redux'
import { addVisitToProduct } from 'modules/Visits/visits.redux'
import { productActions } from 'modules/Product/product.redux'
import { Link } from 'react-router-dom'

// mapear prodiedas
// agregar actions de shoppingcart
// agregar actions de whishlist
function ProductCard({
  addProductToWishList,
  addProductToShoppingCart,
  addVisitToProduct,
  product,
  size
}) {
  return product ? (
    <div className={`${size}`} key={product.id}>
      <div className='product-item'>
        <div className='product-item-pic set-bg6'>
          <div className='product-item-img-container'>
            {/* Check persisted data */}
            <Link to={`/products/${product.id}`} onClick={() => addVisitToProduct(product)}>
              <img src={product.thumbnail ? product.thumbnail.path : ''} alt={product.name} />
            </Link>
          </div>
          <ul className='product-item-pic-hover'>
            <li>
              <button
                value={product.name}
                onClick={() => {
                  addProductToWishList(product)
                }}>
                <i className='icon-heart' />
              </button>
            </li>
            <li>
              <button onClick={() => addProductToShoppingCart(product)}>
                <i className='icon-shopping-cart' />
              </button>
            </li>
          </ul>
        </div>
        <Link to={`/products/${product.id}`} onClick={() => addVisitToProduct(product)}>
          {product.stock > 0 ? (
            <div className='product-item-text'>
              <span>{[product.category.title]}</span>
              <h4>{product.name}</h4>
              <h6>{product.price} Puntos</h6>
              <h6>{product.stock} Piezas</h6>
            </div>
          ) : (
            <div className='product-item-text'>
              <span>{[product.category.title]}</span>
              <h4>{product.name}</h4>
              <h6>{product.price} Puntos</h6>
              <h6 className='product-out-stock'>Sin disponibilidad</h6>
            </div>
          )}
        </Link>
      </div>
    </div>
  ) : null
}

ProductCard.defaultProps = {
  size: ''
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {
  addProductToShoppingCart: addProductToShoppingCart,
  addProductToWishList: addProductToWishList,
  addVisitToProduct: addVisitToProduct,
  loadAllPublicProduct: productActions.loadAllPublicProductRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)
