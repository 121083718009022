import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { productActions } from 'modules/Product/product.redux'
import ListProductItem from './ListProductItem'

// Se muestra en Pagina Home con diferente grid
// Crear Query Para solo mostrar 3 productos Reated
export const LatestProducts = ({ products, loadLatestProducts }) => {
	useEffect(() => {
		loadLatestProducts()
	}, [])

	return (
		<div className="col-lg-4 col-md-6">
			<div className="latest-product-text">
				<h4>Últimos Productos</h4>
				<div className="latest-product-slider">
					{products && products.map((p) => <ListProductItem key={p.id} product={p} />)}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({ product }) => ({
	products: product.latestProducts
})
const mapDispatchToProps = {
	loadLatestProducts: productActions.loadLatestProductsRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(LatestProducts)
