/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Public from 'components/Layout/Layout'

function EmptyCard({ title, context, description, page, pageText }) {
	return (
		<Public admin={false}>
			<section className="testimony-section py-5 pb-3">
				<div className="container">
					<div className="mb-2 header-h1">
						<h2 className="mb-3 text-left">{title}</h2>
					</div>
					<div className="alert-empty">
						<div className="alert alert-info" role="alert">
							<h4 className="alert-heading">{context}</h4>
							<p className="mb-0">
								{'Te invitamos a ver nuestros' || description}{' '}
								<a
									style={{ color: '#0c5460', fontWeight: 'bold', textDecoration: 'underline' }}
									href={'/shop' || page}
								>
									{' '}
									{'productos' || pageText}{' '}
								</a>
							</p>
						</div>
					</div>
				</div>
			</section>
		</Public>
	)
}
export default EmptyCard
