import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Router from './components/Router/Router'
import { actions } from 'modules/Auth/auth.redux'
import { routes } from './routes'
import { configurationActions } from 'modules/Configuration/configuration.redux'
import { getShoppingCart } from 'redux/global/shoppingCart/shoppingCart.redux'
import { getWishList } from 'redux/global/wishList/wishList.redux'
import { pointActions } from 'modules/Points/point.redux'
import { orderActions } from 'modules/Order/order.redux'
import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'

function App(props) {
	const dispatch = useDispatch()

	const { isAuthenticated, products } = useSelector((state) => ({
		isAuthenticated: state.auth.isAuthenticated,

		products: state.product.publicList
	}))

	useEffect(
		() => {
			if (products.length === 0) dispatch(configurationActions.publicLoadConfigurationRequest())

			if (isAuthenticated) {
				dispatch(orderActions.loadOrderByUserRequest())
				dispatch(pointActions.loadPointByUserRequest())
				dispatch(pointActions.loadAllPointsRequest())
				dispatch(getShoppingCart())

				dispatch(getWishList())
			} else {
				dispatch(actions.checkLoginRequest())
			}
		},
		[ isAuthenticated ]
	)

	return (
		<Fragment>
			<Router routes={routes} />
		</Fragment>
	)
}

export default App
