import { isEmpty } from '../../util/helpers'

export const triggerPB = (type) => {
	if (
		!isEmpty(type) &&
		(type.includes('CREATE') || type.includes('UPDATE') || type.includes('TOGGLE') || type.includes('TOGGLE')) &&
		type.substring(type.length - 8) === '_REQUEST'
	) {
		return true
	}
	return false
}

export const progressBar = (state = false, action) => triggerPB(action.type)
