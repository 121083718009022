import { addItemToWishList, removeItemFromWishList } from './wishList.helpers'

// Types
export const ADD_PRODUCT_TO_WISHLIST_REQUEST = 'ADD_PRODUCT_TO_WISHLIST_REQUEST'
export const ADD_PRODUCT_TO_WISHLIST_SUCCESS = 'ADD_PRODUCT_TO_WISHLIST_SUCCESS'

export const REMOVE_PRODUCT_FROM_WISHLIST_REQUEST = 'REMOVE_PRODUCT_TO_WISHLIST_REQUEST'
export const REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS = 'REMOVE_PRODUCT_TO_WISHLIST_SUCCESS'

export const GET_WISHLIST_REQUEST = 'GET_WISHLIST_REQUEST'
export const GET_WISHLIST_REQUEST_SUCCESS = 'GET_WISHLIST_REQUEST_SUCCESS'

export const RESETWL = 'RESETWL'
// Actions
export const addProductToWishList = (product) => ({
	type: ADD_PRODUCT_TO_WISHLIST_REQUEST,
	product
})

export const removeProductFromWishList = (wishListId) => ({
	type: REMOVE_PRODUCT_FROM_WISHLIST_REQUEST,
	wishListId
})

export const getWishList = () => ({ type: GET_WISHLIST_REQUEST })

// Reducer
const initialState = []

export function wishList(state = initialState, action) {
	switch (action.type) {
		case ADD_PRODUCT_TO_WISHLIST_SUCCESS:
		case GET_WISHLIST_REQUEST_SUCCESS:
			return addItemToWishList(state, action.product, action.whishListId)

		case REMOVE_PRODUCT_FROM_WISHLIST_REQUEST:
			return removeItemFromWishList(state, action.wishListId)

		default:
			return state
	}
}
