import React from 'react'
import { reduxForm } from 'redux-form'
import FormContainer from 'components/Container/FormContainer'
import { Form, Row } from 'reactstrap'
import { withForm } from 'util/withForm'
import { RFInput } from 'components/Inputs/RFFields'
import { required } from 'util/form'
import { connect } from 'react-redux'

function MailerForm({ options, ...props }) {
	const { handleSubmit, onCancel, label, button, option, ...others } = props
	const { pristine, valid, submitting } = others

	return (
		<FormContainer
			title="Configuración de correo electrónico"
			label={label}
			button={button}
			onCancel={onCancel}
			onClick={handleSubmit}
			status={{ pristine, valid, submitting }}
		>
			<Form>
				<Row style={{ minHeight: 287 }}>
					<RFInput col="6" name="mailer.auth.user" label="Correo" validate={[ required ]} />
					<RFInput
						col="6"
						name="mailer.auth.pass"
						type="password"
						label="Contraseña"
						validate={[ required ]}
					/>
					<RFInput col="6" name="mailer.host" label="Host" validate={[ required ]} />
					<RFInput col="6" name="mailer.port" label="Puerto" type="number" validate={[ required ]} />
				</Row>
			</Form>
		</FormContainer>
	)
}

const mapStateToProps = ({ configuration }) => ({
	configuration
})
const withConnect = connect(mapStateToProps)

export default withConnect(
	withForm(
		reduxForm({
			form: 'configuration'
		})(MailerForm)
	)
)
