import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import { Row, Col } from 'reactstrap'
import { mdy } from './../../util/dates'
import { isEmpty } from './../../util/crud'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'
import { orderActions } from './order.redux'
import { getStatus, getTotal, getTotalQuantity } from 'util/helpers'
class OrderView extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.loadOrderRequest({ id })
  }

  componentWillUnmount() {
    this.props.loadOrderSuccess()
  }

  render() {
    const { view } = this.props.order

    return (
      <Layout>
        {view.status === 3 || view.status === 2 ? (
          <TableContainer
            title='Orden'
            subtitle='Información del registro'
            view={view}
            inactive
            showButtons={false}>
            {!isEmpty(view) && (
              <Row id='view-order'>
                <Col lg='6'>
                  <ul>
                    <ListItem label='Estatus' value={getStatus(view.status)} />
                    <ListItem label='Total' value={getTotal(view.products)} />
                    <ListItem label='Cantidad' value={getTotalQuantity(view.products)} />
                    <ListItem label='Creado' value={mdy(view.createdAt)} />
                  </ul>
                </Col>
                <Col lg='6'>
                  <li className='list-unstyled' style={{ color: '#858796', padding: '.2rem' }}>
                    <strong>Productos:</strong>
                    <ul>
                      {view.products.map(p => (
                        <li key={p.product}>
                          {p.product.name} - {p.product.price} - Cantidad {p.quantity}
                        </li>
                      ))}
                    </ul>
                  </li>
                </Col>
              </Row>
            )}
          </TableContainer>
        ) : (
          <TableContainer title='Orden' subtitle='Información del registro' view={view} inactive>
            {!isEmpty(view) && (
              <Row id='view-order'>
                <Col lg='6'>
                  <ul>
                    <ListItem label='Estatus' value={getStatus(view.status)} />
                    <ListItem label='Total' value={getTotal(view.products)} />
                    <ListItem label='Cantidad' value={getTotalQuantity(view.products)} />
                    <ListItem label='Creado' value={mdy(view.createdAt)} />
                  </ul>
                </Col>
                <Col lg='6'>
                  <li className='list-unstyled' style={{ color: '#858796', padding: '.2rem' }}>
                    <strong>Productos:</strong>
                    <ul>
                      {view.products.map(p => (
                        <li key={p.product}>
                          {p.product.name} - {p.product.price} - Cantidad {p.quantity}
                        </li>
                      ))}
                    </ul>
                  </li>
                </Col>
              </Row>
            )}
          </TableContainer>
        )}
      </Layout>
    )
  }
}

const mapStateToProps = ({ order }) => ({ order })
export default connect(mapStateToProps, orderActions)(OrderView)
