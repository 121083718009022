/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns } from './product.helpers'
import { productActions } from './product.redux'
import Layout from 'components/Layout/Layout'
import TableContainer from 'components/Container/TableContainer'
import DataTable from 'components/Table/Table'
import { useExportSelected } from 'hooks/useExportSelected'

function ProductList(props) {
	const [ selectRow, handleExport ] = useExportSelected('Productos', '/api/products/export')

	useEffect(() => {
		props.loadAllProduct()
	}, [])

	const rowEvents = {
		onClick: (e, row) => {
			props.history.push(`/dashboard/product/view/${row._id}`)
		}
	}

	return (
		<Layout>
			<TableContainer title="Productos" subtitle="Lista" route="/dashboard/product/create" mbodule="Productos">
				<DataTable
					{...props}
					rowEvents={rowEvents}
					data={props.products}
					structure={columns}
					onExport={handleExport}
					selectRow={selectRow}
					selected={selectRow.selected}
				/>
			</TableContainer>
		</Layout>
	)
}

const mapStateToProps = ({ product }) => ({ products: product.list })
const mapDispatchToProps = {
	loadAllProduct: productActions.loadAllProductRequest,
	toggleProduct: productActions.toggleProductRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
