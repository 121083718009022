import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col } from 'reactstrap'
import Layout from 'components/Layout/Layout'
import LoginForm from './LoginForm'
import BannerShopProducts from 'components/Banners/BannerShopProducts'

function Login({ auth, history }) {
	useEffect(() => {
		if (auth.isAuthenticated) {
			history.push('/')
		}
	})

	return (
		<Layout admin={false}>
			<BannerShopProducts text="Iniciar sesión" />
			<Col md="5" className="login-box mt-2 mb-5 p-4 mx-auto">
				<LoginForm />
			</Col>
		</Layout>
	)
}

const mapStateToProps = ({ auth }) => ({ auth })
const withConnect = connect(mapStateToProps)

export default withConnect(Login)
