import axios from 'axios'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { trackingActions, trackingTypes } from './tracking.redux'

/**
 * Display a single with trackings record
 * @param   {object}   action.payload Data to take the id of the requested orders
 * @returns {iterator}
 */
export function* getTrackingByOrder({ payload } = {}) {
	try {
		const { id } = payload
		const url = `/api/trackings/byOrder/${id}`
		const { data: trackings } = yield call(axios.get, url)
		yield put(trackingActions.loadTrackingByOrderSuccess(trackings))
	} catch (error) {
		yield put(trackingActions.loadTrackingByOrderFailure(error))
	}
}

export function* trackingSagas() {
	yield all([ takeLatest(trackingTypes.LOAD_TRACKING_BY_ORDER_REQUEST, getTrackingByOrder) ])
}
