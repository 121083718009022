import React from 'react'
import Review from './Review'
import ReviewBox from './ReviewBox'
import { connect } from 'react-redux'
import userThumbnail from 'assets/images/conversation.svg'

function ReviewsList({ auth, reviews }) {
	const canReview = auth.isAuthenticated

	return (
		<div className="mt-5">
			<ul className="comment-list overflow-box" style={{ maxHeight: 550, overflowY: 'auto' }}>
				{reviews.map(({ createdBy = {}, ...review }, i) => (
					<Review {...review} key={`review-${i}`} picture={userThumbnail} author={`${createdBy}`} />
				))}
			</ul>
			{canReview && <ReviewBox />}
		</div>
	)
}

const withConnect = connect(({ auth, reviews }) => ({
	auth,
	reviews: reviews.list
}))
export default withConnect(ReviewsList)

ReviewsList.defaultProps = {
	reviews: []
}
