import ProductCreate from './ProductCreate'
import ProductList from './ProductList'
import ProductEdit from './ProductEdit'
import ProductView from './ProductView'
import Product from 'components/Products/Product'

const routes = [
	{ path: '/products/:id', public: true, component: Product },
	{ path: '/dashboard/product/list', component: ProductList },
	{ path: '/dashboard/product/create', component: ProductCreate },
	{ path: '/dashboard/product/edit/:id', component: ProductEdit },
	{ path: '/dashboard/product/view/:id', component: ProductView }
]

export default routes
