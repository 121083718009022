/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import { isEmpty } from 'util/crud'
import Public from 'components/Layout/Layout'
import { connect } from 'react-redux'
import { mdyhm } from 'util/dates'
import picture from 'assets/images/lightYellow.svg'
import { trackingActions } from './tracking.redux'
import EmptyCard from 'components/Cards/EmptyCard'

function TrackingByOrder({ loadTrackingsByOrder, trackings }) {
	const { id } = useParams()
	useEffect(() => {
		loadTrackingsByOrder({ id })
	}, [])

	if (isEmpty(trackings))
		return <EmptyCard title="Seguimiento de Orden" context="Por el momento no existen cambios en tu orden" />

	return (
		<Public admin={false}>
			<section className="testimony-section py-5 pb-3">
				<div className="container">
					<div className="mb-2 header-h1">
						<h2 className="mb-3 text-left">Seguimiento de Orden</h2>
					</div>
					<Timeline lineColor="#fad868ce">
						{trackings.map((t) => {
							if (isEmpty(t.cause)) return <h5 className="text-center my-4">Sin cambios registrados</h5>
							return (
								<TimelineEvent
									title={`Movimiento - ${t.cause}`}
									createdAt={`${mdyhm(t.createdAt)} - ${t.createdBy}`}
									container="card"
									titleStyle={{ fontSize: 14.5 }}
									contentStyle={{
										fontSize: 15,
										fontWeight: '300',
										backgroundColor: 'white',
										paddingLeft: 19
									}}
									cardHeaderStyle={{
										backgroundColor: 'whitesmoke',
										color: '#2d2d2d',
										borderLeft: `4px solid #fdcc24`,
										paddingLeft: 14
									}}
									bubbleStyle={{ borderColor: 'whitesmoke' }}
									icon={<img src={picture} alt={t.createdBy} />}
								>
									<p className="mb-0">{t.comment}</p>
								</TimelineEvent>
							)
						})}
					</Timeline>
				</div>
			</section>
		</Public>
	)
}

const mapStateToProps = ({ tracking }) => ({
	trackings: tracking.trackingList.list
})

const mapDispatchToProps = {
	loadTrackingsByOrder: trackingActions.loadTrackingByOrderRequest
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default withConnect(TrackingByOrder)
