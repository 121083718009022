import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { categoryActions } from 'modules/Category/category.redux'
import { productActions } from 'modules/Product/product.redux'
import Slick from 'react-slick'
import sliderConfig from '../Slider/slider.config'

function Carousel({ categories, loadCategories, searchProductsByCategory }) {
	useEffect(() => {
		loadCategories()
	}, [])

	const handleClick = (e) => {
		e.preventDefault()
		const title = e.currentTarget.getAttribute('value')
		searchProductsByCategory({ title })
	}

	const configuration = sliderConfig(categories.length)
	return (
		<section className="categories">
			<div className="container p-0">
				<Slick {...configuration}>
					{categories.map((c) => (
						<div className="categories-size" key={c.id}>
							<div
								className="categories-item set-bg"
								style={{ backgroundImage: `url(${c.thumbnail.path})` }}
							>
								<h5>
									<span 
										type="submit"
										onClick={handleClick}
										role="presentation"
										value={c.title}
										className="dropdown-item-categories"
										key={c.id}
									>
										{c.title}
									</span>
								</h5>
							</div>
						</div>
					))}
				</Slick>
			</div>
		</section>
	)
}

const mapStateToProps = ({ category }) => ({
	categories: category.publicList
})
const mapDispatchToProps = {
	loadCategories: categoryActions.loadAllPublicCategoryRequest,
	searchProductsByCategory: productActions.searchProductByCategoryRequest

}
export default connect(mapStateToProps, mapDispatchToProps)(Carousel)
