import { createActions, createReducer } from 'reduxsauce'

const { Types, Creators } = createActions(
	{
		loadTrackingByOrderRequest: [ 'payload' ],
		loadTrackingByOrderSuccess: [ 'payload' ],
		loadTrackingByOrderFailure: [ 'payload' ]
	},
	{}
)

const initialState = {
	trackingList: [],
	error: {},
	loading: false
}

export function getTrackingByOrder(state, action) {
	return { ...state, trackingList: { ...action.payload } }
}

export const HANDLERS = {
	[Types.LOAD_TRACKING_BY_ORDER_SUCCESS]: getTrackingByOrder
}

export const trackingTypes = Types
export const trackingActions = Creators
export const tracking = createReducer(initialState, HANDLERS)
