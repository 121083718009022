import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './../../redux/middlewares'
import { ScrollToTop } from './ScrollToTop'
import Alert from 'modules/Alert/Alert'

function whichPermission(action) {
	if (action === 'edit') return 'update'
	else if (action === 'create') return action
	else return 'read'
}

function hasAccess(path, permissions) {
	const route = path.split('/')
	const action = whichPermission(route[3])
	const permission = permissions.find((p) => p.module && p.module.app === `/dashboard/${route[2]}` && p[action])
	return Boolean(permission)
}

export function Router({ auth, routes }) {
	const routesList = routes.map((r, i) => {
		const { user, isAuthenticated } = auth
		const route = <Route key={`r-${i}`} exact path={r.path} component={r.component} />

		// return route
		if (r.public === true) return route

		if (isAuthenticated) {
			if (user.changePassword && r.path !== '/reset-password') {
				return <Redirect from={r.path} to="/reset-password" />
			} else if (user.role === 0 || r.bypass) return route
			else if (user.role === 1 ) {
				const canAccess = hasAccess(r.path, user.permissions)
				if (canAccess || r.path === '/') return route
			}
		}

		return <Redirect key={`r-${i}`} from={r.path} to="/login" />
	})

	return (
		<ConnectedRouter history={history}>
			<Switch>
				{routesList}
				<Route render={() => <Redirect to="/" />} />
			</Switch>
			<Alert />
			<ScrollToTop />
		</ConnectedRouter>
	)
}

const mapStateToProps = ({ auth }) => ({ auth })
export default connect(mapStateToProps)(Router)
