// Types
export const ADD_VISIT_TO_PRODUCT_REQUEST = 'ADD_VISIT_TO_PRODUCT_REQUEST'
export const ADD_VISIT_TO_PRODUCT_SUCCESS = 'ADD_VISIT_TO_PRODUCT_SUCCESS'

export const GET_VISITS_FROM_PRODUCT_REQUEST = 'GET_VISITS_FROM_PRODUCT_REQUEST'
export const GET_VISITS_FROM_PRODUCT_SUCCESS = 'GET_VISITS_FROM_PRODUCT_SUCCESS'

// Actions
export const addVisitToProduct = (product) => ({
	type: ADD_VISIT_TO_PRODUCT_REQUEST,
	product
})

export const getVisitsFromProduct = (product) => ({
	type: GET_VISITS_FROM_PRODUCT_REQUEST,
	product
})

// Reducer
const initialState = null

export function visits(state = initialState, action) {
	switch (action.type) {
		case ADD_VISIT_TO_PRODUCT_SUCCESS:
		case GET_VISITS_FROM_PRODUCT_SUCCESS:
			return action.visits
		default:
			return state
	}
}
