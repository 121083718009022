import LoadList from './LoadList'
import LoadCreate from './LoadCreate'
import LoadView from './LoadView'

const routes = [
  { path: '/dashboard/load/list', component: LoadList },
  { path: '/dashboard/load/create', component: LoadCreate },
  { path: '/dashboard/load/view/:id', component: LoadView }
]

export default routes
