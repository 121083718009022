import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take } from 'redux-saga/effects'
import { alertActions, alertTypes } from '././../Alert/alert.redux'
import { configurationActions, configurationTypes } from './configuration.redux'
import { isEmpty } from './../../util/crud'
import { decryptionAES } from 'util/encryption'

/**
 * Display a single configuration record
 * @param   {object}   action.payload Data to take the id of the requested configuration
 * @returns {iterator}
 */
export function* loadConfiguration({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/configuration/${id}`
    const { data: configuration } = yield call(axios.get, url)
    yield put(configurationActions.loadConfigurationSuccess(configuration))
  } catch (error) {
    yield put(configurationActions.loadConfigurationFailure(error))
    console.log(error)
  }
}

/**
 * Display a single configuration record
 * @param   {object}   action.payload Data to take the id of the requested configuration
 * @returns {iterator}
 */
export function* publicLoadConfiguration() {
  try {
    const url = `/api/init/`
    const { data: configuration } = yield call(axios.get, url)
    if (configuration.configured === false) {
      //yield put(push('/configure'))
    } else {
      yield put(configurationActions.publicLoadConfigurationSuccess(configuration))
    }
  } catch (error) {
    yield put(configurationActions.publicLoadConfigurationFailure(error))
    console.log(error)
  }
}

/**
 * Load the information of a single configuration record to edit it
 * @param   {object}   action.payload Data to take the id of the requested configuration
 * @returns {iterator}
 */
export function* editConfiguration() {
  try {
    const url = `/api/configuration/edit`
    const { data } = yield call(axios.get, url)

    const configuration = data

    if (configuration.mailer?.auth?.pass)
      configuration.mailer.auth.pass = decryptionAES(configuration.mailer.auth.pass)

    yield put(configurationActions.editConfigurationSuccess(configuration))
  } catch (error) {
    yield put(configurationActions.editConfigurationFailure(error))
    console.log(error)
  }
}

/**
 * Update an configuration record
 * @param   {object}   action.payload Data to update an configuration record
 * @returns {iterator}
 */
export function* updateConfiguration({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const url = `/api/configuration`
    const historical = { cause: 'Actualización', description: prompt.payload }
    const data = { payload: payload.values, historical }
    const { data: updatedConfiguration } = yield call(axios.put, url, data)
    yield put(configurationActions.updateConfigurationSuccess(updatedConfiguration))
    // Success notification and return the configuration to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    yield put(configurationActions.publicLoadConfigurationRequest())
    yield put(push('/dashboard/menu'))
  } catch (error) {
    yield put(configurationActions.updateConfigurationFailure(error))
    console.log(error)
  }
}

/**
 * Create a configuration record
 * @param   {object}   action.payload Data to take the id of the requested configuration
 * @returns {iterator}
 */
export function* createConfiguration({ payload }) {
  try {
    const url = `/api/init/`
    const { data: configuration } = yield call(axios.post, url, payload)
    yield put(configurationActions.publicLoadConfigurationRequest())
    yield put(configurationActions.createConfigurationSuccess(configuration))
    yield put(alertActions.alertMessageSuccess('Configuración exitosa'))
    yield put(push('/dashboard/menu'))
  } catch (error) {
    yield put(configurationActions.createConfigurationFailure(error))
    console.log(error)
  }
}

/**
 * Validate an configuration record
 * @param     values
 * @returns
 */
export async function validateConfiguration(values) {
  const url = '/api/configuration/validate'
  const { data: result } = await axios.post(url, values)
  if (!isEmpty(result.errors)) throw result.errors
}

export function* configurationSagas() {
  yield all([
    takeLatest(configurationTypes.PUBLIC_LOAD_CONFIGURATION_REQUEST, publicLoadConfiguration),
    takeLatest(configurationTypes.LOAD_CONFIGURATION_REQUEST, loadConfiguration),
    takeLatest(configurationTypes.EDIT_CONFIGURATION_REQUEST, editConfiguration),
    takeLatest(configurationTypes.CREATE_CONFIGURATION_REQUEST, createConfiguration),
    takeLatest(configurationTypes.UPDATE_CONFIGURATION_REQUEST, updateConfiguration)
  ])
}
