import { mdy } from './../../util/dates'
import { getStatus, getTotal, getTotalQuantity } from 'util/helpers'

export const columns = [
	{
		dataField: 'status',
		text: 'Estatus',
		formatter: (cell) => getStatus(cell),
		sort: true
	},
	{
		dataField: 'products',
		text: 'Puntos',
		formatter: (cell) => getTotal(cell),
		sort: true
	},
	{
		dataField: 'products',
		text: 'Productos',
		formatter: (cell) => getTotalQuantity(cell),
		sort: true
	},
	{
		dataField: 'updatedAt',
		text: 'Última modificación',
		sort: true,
		formatter: mdy
	},
	{
		dataField: 'createdAt',
		text: 'Creado',
		sort: true,
		formatter: mdy
	},
	{
		dataField: 'user.fullName',
		text: 'Creado por',
		sort: true
	},
	{
		dataField: 'user.email',
		text: 'Email',
		sort: true
	}
]
