import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'
import { getTotalPoints } from './load.helpers'

export const GET_TOTAL_POINTS_REQUEST = 'GET_TOTAL_POINTS_REQUEST'
export const GET_TOTAL_POINTS_SUCCESS = 'GET_TOTAL_POINTS_SUCCESS'

export const getTotalPoint = () => ({
  type: GET_TOTAL_POINTS_REQUEST
})

const { Types, Creators } = createActions(
  {
    loadAllLoadRequest: ['payload'],
    loadAllLoadSuccess: ['payload'],
    loadAllLoadFailure: ['payload'],

    createLoadRequest: ['payload'],
    createLoadSuccess: ['payload'],
    createLoadFailure: ['error'],

    loadLoadRequest: ['payload'],
    loadLoadSuccess: ['payload'],
    loadLoadFailure: ['payload']
  },
  {}
)

const initialState = { list: [], points: {}, total: 0, error: {}, loading: false }

export function points(state = initialState, action) {
  switch (action.type) {
    case GET_TOTAL_POINTS_SUCCESS:
      return {
        total: getTotalPoints(state.points, action.point)
      }
    default:
      return state
  }
}

export function getError(state, action) {
  return {
    ...state,
    error: action.error
  }
}

export const HANDLERS = {
  [Types.LOAD_ALL_LOAD_SUCCESS]: handlers.loadAllSuccess,
  [Types.CREATE_LOAD_SUCCESS]: handlers.createSuccess,
  [Types.LOAD_LOAD_SUCCESS]: handlers.loadSuccess,
  [Types.CREATE_LOAD_FAILURE]: getError
}

export const loadTypes = Types
export const loadActions = Creators
export const load = createReducer(initialState, HANDLERS)
