import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import { isEmpty } from './../../util/crud'
import { configurationActions } from './configuration.redux'
import MailerForm from './forms/MailerForm'

class ConfigurationEdit extends Component {
	componentDidMount() {
		this.props.editConfigurationRequest()
	}

	componentWillUnmount() {
		this.props.editConfigurationSuccess()
	}

	submit = (values) => {
		this.props.updateConfiguration({ values })
	}

	render() {
		const values = this.props.configuration.edit

		return (
			<Layout>
				{!isEmpty(values) && (
					<MailerForm
						{...this.props}
						label="Actualizar"
						button="edit"
						onSubmit={this.submit}
						initialValues={values}
					/>
				)}
			</Layout>
		)
	}
}

const mapStateToProps = ({ configuration }) => ({ configuration })
const mapDispatchToProps = {
	updateConfiguration: configurationActions.updateConfigurationRequest,
	editConfigurationRequest: configurationActions.editConfigurationRequest,
	editConfigurationSuccess: configurationActions.editConfigurationSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationEdit)
