import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { isEmpty } from './../../util/crud'
import { alertActions } from 'modules/Alert/alert.redux'
import {
  GET_TOTAL_POINTS_SUCCESS,
  GET_TOTAL_POINTS_REQUEST,
  loadActions,
  loadTypes
} from './load.redux'
import { pointActions } from 'modules/Points/point.redux'

// Load list records
export function* loadAllLoadSaga() {
  try {
    const { data: loads } = yield call(axios.get, '/api/load/')
    if (isEmpty(loads)) {
      yield put(alertActions.alertMessageEmpty('load', '/dashboard/load/create'))
    }
    yield put(loadActions.loadAllLoadSuccess(loads))
  } catch (error) {
    yield put(alertActions.alertMessageError('Problema al cargar datos'))
  }
}

// Create load points record
export function* createLoadPointSaga({ payload }) {
  try {
    const url = '/api/load/'
    const loads = payload
    const data = { ...loads }
    // post req
    const { data: createLoad } = yield call(axios.post, url, data)
    // add new document
    yield put(loadActions.createLoadSuccess(createLoad))
    yield put(alertActions.alertMessageSuccess('Registro guardado'))
    yield put(pointActions.loadPointByUserRequest())
    yield put(push('/dashboard/load/list'))
  } catch (error) {
    yield put(loadActions.createLoadFailure({ error }))
  }
}

// Display a record
export function* loadLoadSaga({ payload }) {
  try {
    const { id } = payload
    const url = `/api/load/${id}`
    const { data: load } = yield call(axios.get, url)
    yield put(loadActions.loadLoadSuccess(load))
  } catch (error) {
    yield put(alertActions.alertMessageError('Problema al cargar datos'))
  }
}

export function* getTotalPointSaga(action) {
  try {
    const getUserId = state => state.auth.user._id
    if (!getUserId) return yield put(alertActions.alertMessageError('Por favor iniciar sesión'))

    const { data: points } = yield call(axios.post, '/api/public/load/getPointsByUser', {
      getUserId
    })

    yield all(
      points.map(row =>
        put({ type: GET_TOTAL_POINTS_SUCCESS, pointsListId: row.id, point: row.point })
      )
    )
  } catch (error) {
    yield put(alertActions.alertMessageError('Problema al cargar datos'))
  }
}

export function* loadSagas() {
  yield all([
    takeLatest(loadTypes.LOAD_ALL_LOAD_REQUEST, loadAllLoadSaga),
    takeLatest(loadTypes.CREATE_LOAD_REQUEST, createLoadPointSaga),
    takeLatest(loadTypes.LOAD_LOAD_REQUEST, loadLoadSaga),
    takeLatest(GET_TOTAL_POINTS_REQUEST, getTotalPointSaga)
  ])
}
