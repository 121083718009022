import React, { useEffect } from 'react'
import { reduxForm } from 'redux-form'
import FormContainer from 'components/Container/FormContainer'
import { Form, Row, Col } from 'reactstrap'
import { withForm } from 'util/withForm'
import { RFInput, RFEmails } from 'components/Inputs/RFFields'
import { required, substring } from 'util/form'
import { connect } from 'react-redux'
import { userActions } from 'modules/Users/user.redux'
import { loadActions } from './load.redux'

const limit280 = substring(0, 280)
const limit20 = substring(0, 20)

function LoadForm({ options, ...props }) {
  useEffect(() => {
    props.loadAllUser()
  }, [])

  const { handleSubmit, onCancel, label, button, option, ...others } = props
  const { pristine, valid, submitting } = others
  const { error } = props.loads

  const alert = error && (
    <div className='alert alert-danger' role='alert'>
      <p>{error.data.message}</p>
      {error.data.emails.map(e => (
        <span className='icon-minus plus'>
          {'       '}
          {e.email}
        </span>
      ))}
    </div>
  )

  return (
    <FormContainer
      title='Puntos'
      label={label}
      button={button}
      onCancel={onCancel}
      onClick={handleSubmit}
      status={{ pristine, valid, submitting }}>
      <Form>
        <Row>
          <Col lg='12'>
            <RFEmails name='emails' label='Correos' type='text' validate={[required]} />
            {alert}
          </Col>
        </Row>
        <Row>
          <Col lg='6'>
            <RFInput
              name='title'
              type='text'
              label='Título'
              normalize={limit20}
              validate={[required]}
            />
          </Col>
          <Col lg='6'>
            <RFInput
              name='points'
              type='number'
              label='Puntos'
              normalize={limit20}
              validate={[required]}
            />
          </Col>
        </Row>
        <RFInput
          name='description'
          type='textarea'
          label='Descripción'
          validate={[required]}
          normalize={limit280}
        />
      </Form>
    </FormContainer>
  )
}

const mapStateToProps = ({ user, load }) => ({ users: user.list, loads: load.error })
const mapDispatchToProps = {
  loadAllUser: userActions.loadAllUserRequest,
  error: loadActions.createLoadFailure
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(
  withForm(
    reduxForm({
      form: 'points'
    })(LoadForm)
  )
)
