import React, { Component } from 'react'
import { connect } from 'react-redux'
import { categoryActions } from './category.redux'
import { isEmpty } from './../../util/crud'
import CategoryForm from './CategoryForm'
import Layout from 'components/Layout/Layout'

class CategoryEdit extends Component {
	componentDidMount() {
		const { id } = this.props.match.params
		this.props.editCategoryRequest({ id })
	}

	componentWillUnmount() {
		this.props.editCategorySuccess()
	}

	onCancel = () => {
		this.props.history.push('/dashboard/category/list')
	}

	submit = (values) => {
		const { id } = this.props.match.params
		this.props.updateCategory({ id, values })
	}

	render() {
		const values = this.props.category.edit

		return (
			<Layout>
				{!isEmpty(values) && (
					<CategoryForm
						{...this.props}
						label="Actualizar"
						button="edit"
						onCancel={this.onCancel}
						onSubmit={this.submit}
						initialValues={values}
					/>
				)}
			</Layout>
		)
	}
}

const mapStateToProps = ({ category }) => ({ category })
const mapDispatchToProps = {
	updateCategory: categoryActions.updateCategoryRequest,
	editCategoryRequest: categoryActions.editCategoryRequest,
	editCategorySuccess: categoryActions.editCategorySuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEdit)
