import React from 'react'

function Section({ children, contain, row }) {
	return (
		<section className={contain}>
			{row ? (
				<div className="container">
					<div className="row">{children}</div>
				</div>
			) : (
				<div className="container">{children}</div>
			)}
		</section>
	)
}

export default Section
