import axios from 'axios'
import { all, takeLatest, call, put, take, select } from 'redux-saga/effects'
import { alertActions } from '././../Alert/alert.redux'
import { reviewsActions, reviewsTypes } from './reviews.redux'

export function* loadProductReviews({ payload: productId } = {}) {
	try {
		const url = `/api/reviews/reviewsByProduct/${productId}`
		const { data: reviews } = yield call(axios.get, url)
		yield put(reviewsActions.loadAllProductReviewsSuccess(reviews))
	} catch (error) {
		yield put(reviewsActions.loadAllProductReviewsFailure(error))
	}
}

export function* createProductReview({ payload }) {
	try {
		const product = yield select((state) => state.product.product)
		const url = `/api/reviews`
		const { data: review } = yield call(axios.post, url, { ...payload, product: product._id })
		yield put(reviewsActions.createProductReviewSuccess(review))
		yield put(alertActions.alertMessageSuccess('Comentario publicado'))
		yield put(reviewsActions.loadAllProductReviewsRequest(product._id))
	} catch (error) {
		yield put(reviewsActions.createProductReviewFailure(error))
	}
}

export function* deleteProductReview({ payload: id }) {
	try {
		const product = yield select((state) => state.product.product)
		const url = `/api/reviews/${id}`
		const { data } = yield call(axios.put, url)
		yield put(reviewsActions.deleteProductReviewSuccess(data))
		yield put(alertActions.alertMessageSuccess('Comentario eliminado'))
		yield put(reviewsActions.loadAllProductReviewsRequest(product._id))
	} catch (error) {
		yield put(reviewsActions.deleteCampaignReviewFailure(error))
	}
}

export function* reviewSagas() {
	yield all([
		takeLatest(reviewsTypes.LOAD_ALL_PRODUCT_REVIEWS_REQUEST, loadProductReviews),
		takeLatest(reviewsTypes.CREATE_PRODUCT_REVIEW_REQUEST, createProductReview),
		takeLatest(reviewsTypes.DELETE_PRODUCT_REVIEW_REQUEST, deleteProductReview)
	])
}
