import React from 'react'
import { connect } from 'react-redux'
import { actions } from 'modules/Auth/auth.redux'
import Img from 'react-cool-img'
import { HashLink as Link } from 'react-router-hash-link'
import logo from 'assets/images/WClub_black.png'
import CategoriesMenu from 'components/Categories/CategoriesMenu'
import { useMsal } from '@azure/msal-react'

function Navbar({ logout, auth: { isAuthenticated, user }, shoppingCart, wishList, points }) {
  const total = shoppingCart.products.length
  const wishTotal = wishList.length
  const totalShoppingCart = shoppingCart.total
  const totalPoints = points - totalShoppingCart

  return (
    <header className='header'>
      <div className='header-top'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='header-top-left'>
                <Link exact='true' className='navbar-brand' to='/'>
                  <Img src={logo} width='60%' />
                </Link>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='header-top-right'>
                <div className='header-top-right-auth'>
                  <ul style={{ listStyle: 'none', margin: 0 }}>
                    {isAuthenticated ? (
                      <AuthNavItem logout={logout} {...user} />
                    ) : (
                      <Link exact='true' className='nav-link primary-btn' to='/login'>
                        Iniciar Sesión
                      </Link>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-2' style={{ padding: 0 }}>
            <div className='header-logo'>
              <CategoriesMenu />
            </div>
          </div>
          <div className='col-lg-8'>
            <nav className='header-menu'>
              <ul>
                <li>
                  <Link exact='true' to='/'>
                    Inicio
                  </Link>
                </li>
                <li>
                  <Link exact='true' to='/shop'>
                    Tienda
                  </Link>
                </li>
                <li>
                  <Link exact='true' to='/shopCart'>
                    Carrito de Compra <i className='icon-shopping-cart' /> <span>{total}</span>
                  </Link>
                </li>
                <li>
                  <Link exact='true' to='/wishList'>
                    Lista de Deseos <i className='icon-heart' /> <span>{wishTotal}</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className='col-lg-2' style={{ padding: 0 }}>
            <div className='header-cart'>
              <div className='header-cart-price'>
                <span> &nbsp;{totalPoints} Puntos</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

const AuthNavItem = ({ logout, name, role, id }) => (
  <li className='nav-item dropdown'>
    <a
      href='/#'
      className='nav-link dropdown-toggle'
      id='auth-dropdown'
      data-toggle='dropdown'
      style={{ fontSize: 16 }}
      aria-expanded='false'>
      <span>Hola, </span> {name}
    </a>
    <ul className='dropdown-menu dropdown-menu-log' aria-labelledby='auth-dropdown'>
      <Link exact='true' className='dropdown-item' to={`/order/${id}`}>
        Mis ordenes
      </Link>
      {(role === 0 || role === 1) && <DashboardItems role={role} id={id} />}
      <li>
        <div
          className='dropdown-item'
          style={{ fontWeight: 'bold' }}
          onClick={logout}
          onKeyPress={() => {}}
          role='presentation'>
          Cerrar sesión
        </div>
      </li>
    </ul>
  </li>
)

const DashboardItems = () => {
  return (
    <li>
      <Link exact='true' className='dropdown-item' to='/dashboard/menu'>
        Panel
      </Link>
    </li>
  )
}

const mapStateToProps = ({ auth, configuration, shoppingCart, wishList, point }) => ({
  auth,
  configuration: configuration.public,
  shoppingCart,
  wishList,
  points: point.points
})
const mapDispatchToProps = { logout: actions.logoutRequest }
const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(Navbar)
