import { productActions } from 'modules/Product/product.redux'
import { categoryActions } from 'modules/Category/category.redux'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

function CategoriesMenu({ categories, display, loadCategories, searchProductsByCategory }) {
	useEffect(() => {
		loadCategories()
	}, [])

	const [ title, setTitle ] = useState('')

	const handleClick = (e) => {
		e.preventDefault()
		const title = e.currentTarget.getAttribute('value')
		searchProductsByCategory({ title })
	}

	return (
		<div className={`dropdown  col-lg-3 hero-categories-all`}>
			<button
				className="dropdown-toggle"
				type="button"
				id="dropdownMenuButton"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<i className="oi oi-menu" />
				Categorías
			</button>
			<div className="dropdown-menu dropdown-categories" aria-labelledby="dropdownMenuButton">
				{categories.map((c) => (
					<span
						type="submit"
						onClick={handleClick}
						role="presentation"
						value={c.title}
						className="dropdown-item-categories"
						key={c.id}
					>
						{c.title}
					</span>
				))}
			</div>
		</div>
	)
}
const mapStateToProps = ({ category }) => ({
	categories: category.publicList
})

const mapDispatchToProps = {
	loadCategories: categoryActions.loadAllPublicCategoryRequest,
	searchProductsByCategory: productActions.searchProductByCategoryRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesMenu)
