import { createActions, createReducer } from 'reduxsauce'

const { Types, Creators } = createActions(
	{
		//* PRODUCT'S REVIEWS *

		loadAllProductReviewsRequest: [ 'payload' ],
		loadAllProductReviewsSuccess: [ 'payload' ],
		loadAllProductReviewsFailure: [ 'payload' ],

		createProductReviewRequest: [ 'payload' ],
		createProductReviewSuccess: [ 'payload' ],
		createProductReviewFailure: [ 'payload' ],

		deleteProductReviewRequest: [ 'payload' ],
		deleteProductReviewSuccess: [ 'payload' ],
		deleteProductReviewFailure: [ 'payload' ]
	},
	{}
)

const initialState = {
	list: []
}

export function loadProductReviews(state, action) {
	return { ...state, list: [ ...action.payload ] }
}

export const HANDLERS = {
	[Types.LOAD_ALL_PRODUCT_REVIEWS_SUCCESS]: loadProductReviews
}

export const reviewsTypes = Types
export const reviewsActions = Creators
export const reviews = createReducer(initialState, HANDLERS)
