import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import { Row, Col } from 'reactstrap'
import Img from 'react-cool-img'
import { mdy } from './../../util/dates'
import { isEmpty } from './../../util/crud'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'
import { productActions } from './product.redux'
class ProductView extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.loadProductRequest({ id })
  }

  componentWillUnmount() {
    this.props.loadProductSuccess()
  }

  render() {
    const { view } = this.props.product
    return (
      <Layout>
        <TableContainer
          title='Producto'
          subtitle='Información del registro'
          toggle={this.props.toggleProductRequest}
          view={view}>
          {!isEmpty(view) && (
            <Row id='view-product'>
              <Col lg='3'>
                <ul>
                  <ListItem label='Nombre' value={view.name} />
                  <ListItem label='Puntos' value={view.price} />
                  <ListItem label='Creado' value={mdy(view.createdAt)} />
                  <ListItem label='Activo' value={view.active} />
                </ul>
              </Col>
              <Col lg='6'>
                <ul>
                  <ListItem label='Categoria' value={view.category.title} />
                  <ListItem label='Stock' value={view.stock} />
                  <ListItem label='Descripción' value={view.description} />
                  <ListItem label='Mostrar comentarios' value={view.visibility.reviews} />
                </ul>
              </Col>
              <Col lg='3' className='d-flex flex-row align-items-center justify-content-center'>
                <Img src={view.thumbnail.path} alt='' width='150' />
              </Col>
            </Row>
          )}
        </TableContainer>
      </Layout>
    )
  }
}

const mapStateToProps = ({ product }) => ({ product })
export default connect(mapStateToProps, productActions)(ProductView)
