import React, { Component } from 'react'
import { connect } from 'react-redux'
import { orderActions } from './order.redux'
import { isEmpty } from './../../util/crud'
import OrderForm from './OrderForm'
import Layout from 'components/Layout/Layout'

class OrderEdit extends Component {
	componentDidMount() {
		const { id } = this.props.match.params
		this.props.editOrderRequest({ id })
	}

	componentWillUnmount() {
		this.props.editOrderSuccess()
	}

	onCancel = () => {
		this.props.history.push('/dashboard/order/list')
	}

	submit = (values) => {
		const { id } = this.props.match.params
		this.props.updateOrder({ id, values })
	}

	render() {
		const values = this.props.order.edit

		return (
			<Layout>
				{!isEmpty(values) && (
					<OrderForm
						{...this.props}
						label="Actualizar"
						button="edit"
						onCancel={this.onCancel}
						onSubmit={this.submit}
						initialValues={values}
						track
					/>
				)}
			</Layout>
		)
	}
}

const mapStateToProps = ({ order }) => ({ order })
const mapDispatchToProps = {
	updateOrder: orderActions.updateOrderRequest,
	editOrderRequest: orderActions.editOrderRequest,
	editOrderSuccess: orderActions.editOrderSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderEdit)
