import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './../../redux/helpers/handlers'

const { Types, Creators } = createActions(
  {
    loadAllPointRequest: ['payload'],
    loadAllPointSuccess: ['payload'],
    loadAllPointFailure: ['payload'],

    loadAllPointsRequest: ['payload'],
    loadAllPointsSuccess: ['payload'],
    loadAllPointsFailure: ['payload'],

    loadPointRequest: ['payload'],
    loadPointSuccess: ['payload'],
    loadPointFailure: ['payload'],

    loadPointByUserRequest: ['payload'],
    loadPointByUserSuccess: ['payload'],
    loadPointByUserFailure: ['payload'],

    loadReportPointsRequest: ['payload'],
    loadReportPointsSuccess: ['payload'],
    loadReportPointsFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  listPoints: [],
  report: [],
  points: 0,
  pointsByUser: 0,
  total: 0,
  loading: false,
  error: {}
}

// Obtiene el estado de los registros de puntos de cada usuarios
export function getPoints(state, action) {
  return {
    ...state,
    points: action.payload,
    loading: false,
    error: {}
  }
}

// Obtiene el estado de los registros de puntos de cada usuarios
export function getPointsByUser(state, action) {
  return {
    ...state,
    pointsByUser: action.payload,
    loading: false,
    error: {}
  }
}

export const HANDLERS = {
  [Types.LOAD_ALL_POINT_SUCCESS]: handlers.loadAllSuccess,
  [Types.LOAD_ALL_POINTS_SUCCESS]: handlers.loadAllPointsSuccess,
  [Types.LOAD_POINT_SUCCESS]: handlers.loadSuccess,
  [Types.LOAD_POINT_BY_USER_SUCCESS]: getPoints,
  [Types.LOAD_REPORT_POINTS_SUCCESS]: handlers.loadReportSuccess
}

export const pointTypes = Types
export const pointActions = Creators
export const point = createReducer(initialState, HANDLERS)
