/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns } from './order.helpers'
import { orderActions } from './order.redux'
import Layout from 'components/Layout/Layout'
import TableContainer from 'components/Container/TableContainer'
import DataTable from 'components/Table/Table'
import { useExportSelected } from 'hooks/useExportSelected'
import { expandRowTracking } from 'components/Table/helpers/index'

function OrderList(props) {
	const [ selectRow, handleExport ] = useExportSelected('Ordenes', '/api/orders/export')

	useEffect(() => {
		props.loadAllOrder()
	}, [])

	const rowEvents = {
		onClick: (e, row) => {
			props.history.push(`/dashboard/order/view/${row._id}`)
		}
	}

	return (
		<Layout>
			<TableContainer title="Ordenes" create subtitle="Lista" mbodule="Ordenes">
				<DataTable
					{...props}
					rowEvents={rowEvents}
					data={props.orders}
					structure={columns}
					onExport={handleExport}
					selectRow={selectRow}
					selected={selectRow.selected}
					expandableRow={expandRowTracking}
				/>
			</TableContainer>
		</Layout>
	)
}

const mapStateToProps = ({ order }) => ({ orders: order.list })
const mapDispatchToProps = {
	loadAllOrder: orderActions.loadAllOrderRequest,
	toggleOrder: orderActions.toggleOrderRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList)
