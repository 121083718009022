/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Public from 'components/Layout/Layout'
import { connect } from 'react-redux'
import { isEmpty } from 'util/crud'
import { Row, Col } from 'reactstrap'
import { ListItem } from 'components/Information/ListItem'
import { mdy } from 'util/dates'
import { orderActions } from 'modules/Order/order.redux'
import { Link } from 'react-router-dom'
import EmptyCard from 'components/Cards/EmptyCard'

function OrderByUser({ order, loadOrderByUser, ...props }) {
	useEffect(() => {
		const { id } = props.match.params
		loadOrderByUser({ id })
	}, [])

	const status = [ 'Pendiente', 'Listo', 'Entregado', 'Cancelado' ]

	if (isEmpty(order)) return <EmptyCard title="Mis ordenes" context="Aún no tienes pedidos" />

	return (
		<Public admin={false}>
			<section className="testimony-section py-5 pb-3">
				<div className="container">
					<div className="mb-2 header-h1">
						<h2 className="mb-3 text-left">Mis ordenes</h2>
					</div>
					<Row>
						{order.map((o, i) => (
							<Col md="6" key={o.id}>
								<Link to={`/order/trackings/${o.id}`}>
									<div
										className="overflow-box"
										style={{
											background: 'whitesmoke',
											padding: 20,
											margin: 10,
											height: '20rem'
										}}
									>
										<h5 style={{ color: 'dimgray' }}>Orden #{i + 1}</h5>
										<ListItem label="Estatus" value={status[o.status]} />
										<li className="list-unstyled" style={{ color: 'dimgray', padding: '.2rem' }}>
											<strong>Productos:</strong>
											<ul>
												{o.products.map((p) => (
													<li key={p.product}>
														{p.product.name} - {p.product.price} - Cantidad {p.quantity}
													</li>
												))}
											</ul>
										</li>
										<ListItem label="Creado" value={mdy(o.createdAt)} />
										<ListItem label="Ultima modificacion" value={mdy(o.updatedAt)} />
									</div>
								</Link>
							</Col>
						))}
					</Row>
				</div>
			</section>
		</Public>
	)
}

const mapStateToProps = ({ order }) => ({
	order: order.userList.orders
})

const mapDispatchToProps = {
	loadOrderByUser: orderActions.loadOrderByUserRequest
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default withConnect(OrderByUser)
