/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import TableContainer from 'components/Container/TableContainer'
import { ListItem } from 'components/Information/ListItem'
import { userActions } from './user.redux'
import { mdy } from 'util/dates'
import { generateActiveIcon } from 'components/Table/helpers/index'
import { Table, Row, Col } from 'reactstrap'
import BasicCard from 'components/Cards/BasicCard'
import { isEmpty } from 'util/crud'
import Acl from 'components/Acl/Acl'
import { Link } from 'react-router-dom'

function UserView(props) {
  useEffect(() => {
    const { id } = props.match.params
    props.loadUser({ id })
  }, [])

  const { view } = props.user

  const roles = ['Administrador total', 'Administrador restringido', 'Usuario']

  return (
    <Layout>
      {!isEmpty(view) && (
        <TableContainer
          title='Usuario'
          subtitle='Información del registro'
          toggle={props.toggleUserRequest}
          view={view}
          canDownload={false}>
          <Row>
            <Col md='6'>
              <ul>
                <ListItem label='Nombre' value={view.name} />
                <ListItem label='Apellidos' value={view.lastName} />
                <ListItem label='Correo electrónico' value={view.email} />
                <ListItem label='Rol' value={roles[view.role]} />
              </ul>
            </Col>
            <Col md='6'>
              <ul>
                <ListItem label='Creado' value={mdy(view.createdAt)} />
                <ListItem label='Última modificación' value={mdy(view.updatedAt)} />
                <ListItem label='Activo' value={view.active} />
              </ul>
            </Col>
          </Row>
        </TableContainer>
      )}

      {view.role === 1 && <PermissionsTable permissions={view.permissions} />}
    </Layout>
  )
}

export const HistoricalUserButton = ({ id }) => {
  return (
    <Acl action='create' module='user'>
      <Link to={`/dashboard/user/view/${id}/historical`}>
        <button className='btn btn-primary'>
          <i className='icon-timeline mr-2' />
          <span>Histórico de cambios</span>
        </button>
      </Link>
    </Acl>
  )
}

export const PermissionsTable = ({ permissions }) => {
  const CheckIcon = ({ value }) => <td>{generateActiveIcon(value)}</td>

  return (
    <div className='w-100'>
      <BasicCard header='Matriz de permisos' className='px-3'>
        <Table>
          <thead>
            <tr className='text-center'>
              <th>Módulo</th>
              <th>Consultar</th>
              <th>Crear</th>
              <th>Actualizar</th>
            </tr>
          </thead>
          <tbody>
            {permissions.map(p => {
              return (
                <tr>
                  <th className='text-center'>{p.module.label}</th>
                  <CheckIcon value={p.read} />
                  <CheckIcon value={p.create} />
                  <CheckIcon value={p.update} />
                </tr>
              )
            })}
          </tbody>
        </Table>
      </BasicCard>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({ user })
const mapDispatchToProps = {
  loadUser: userActions.loadUserRequest,
  toggleUserRequest: userActions.toggleUserRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(UserView)
