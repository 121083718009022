import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import logo from 'assets/images/Softtek.png'

function Footer() {
	return (
		<footer className="footer">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-md-3">
						<div className="footer-widget">
							<img className="logo-size" src={logo} alt="" />
							<div className="footer-widget-social">
								<a href="https://twitter.com/Softtek" target="_blank">
									<span className="icon-twitter" />
								</a>

								<a href="https://www.facebook.com/softtek" target="_blank">
									<span className="icon-facebook" />
								</a>

								<a href="https://www.instagram.com/softtekofficial/" target="_blank">
									<span className="icon-instagram" />
								</a>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-3 col-sm-3">
						<div className="footer-about">
							<div className="footer-about-logo">
								<span>Contacto</span>
							</div>
							<ul>
								<li>
									<span className="text">
										Constitución 3098-piso 6, Santa María, 64650 Monterrey, N.L.
									</span>
								</li>
								<li>
									<a href="tel: 81 1932 4400">
										<span className="text">81 1932 4400</span>
									</a>
								</li>
								<li>
									<a href="mailto:myrewards@softtek.com">
										<span className="text">myrewards@softtek.com</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-2 col-md-2 col-sm-2 offset-1">
						<div className="footer-widget">
							<h6>Secciones</h6>
							<ul className="text">
								<li>
									<Link to="/shop">Tienda</Link>
								</li>
								<li>
									<Link to="/login">Inicio Sesión</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-3 col-sm-3 ">
						<div className="footer-widget">
							<h6>Legal</h6>
							<ul className="text">
								<li>
									<Link to="/notice-of-privacy">Aviso de Privacidad</Link>
								</li>
								<li className="py-2">
									<span>&copy; Copyright {new Date().getFullYear()}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
