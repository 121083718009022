import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import DataTable from 'components/Table/Table'
import { loadActions } from './load.redux'
import { columnsPoints } from './load.helpers'
import { isEmpty } from '../../util/helpers'
import FormContainer from 'components/Container/FormContainer'
import ExportExcel from 'components/Table/ExportExcel'

function LoadView(props) {

	useEffect(() => {
		const { id } = props.match.params
		props.loadLoad({ id })
	}, [])

	const { view } = props.load

	const data =
		view &&
		view.points.map((p) => ({
			fullName: p.user.fullName,
			description: p.description,
			points: p.points,
			email: p.user.email
		}))

	const columnsToExport = [
		{ title: 'Nombre Completo', value: (row) => row.fullName },
		{ title: 'Descripcion', value: (row) => row.description },
		{ title: 'Puntos', value: (row) => row.points },
		{ title: 'Email', value: (row) => row.email }
	]

	return (
		<Layout>
			<FormContainer title="Puntos" label="Carga de puntos">
				<DataTable
					{...props}
					data={data}
					structure={columnsPoints}
					columnsToExport={columnsToExport}
					name="Detalle"
					canDownload={false}
					canExport
				/>
			</FormContainer>
		</Layout>
	)
}

const mapStateToProps = ({ load }) => ({ load })
const mapDispatchToProps = {
	loadLoad: loadActions.loadLoadRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(LoadView)
